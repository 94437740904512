import React, { useEffect, useState } from 'react';
import { observer, useLocalStore } from 'mobx-react-lite';
import { Wrapper, Icon, FlexBox, Text, BorderBox, Button, Section, DropBox } from '../../../modules/globalStyle';
import { useStore } from '../../../store';
import { LpMiningHeader } from '../../../components/LpMiningHeader';
import { WithdrawViewForETH } from '../../../components/WithdrawViewForETH';
import axios from 'axios';
import { getUserSignatureLog, getSignatures } from '../../../store/lib/GetLog';
import { JsonRpcProvider } from '@ethersproject/providers';
import { hooks } from '../../../lib/hooks';
import BigNumber from 'bignumber.js';
import { styled } from '../../../modules';

let withdrawStatusId, unwrapperStatusId;
let curStatusType;
let curSignBlockNumber;

let isSendingTx = false;

export const Withdraw = observer((props) => {
  const { lang, god, dev, base } = useStore();

  function getStatus(id, relayerURL, options) {
    return new Promise((resolve) => {
      async function getRelayerStatus() {

        // console.log('id', id);

        // console.log('curStatusType', curStatusType);
        // console.log('withdrawStatusId', withdrawStatusId);
        // console.log('unwrapperStatusId', unwrapperStatusId);
        if(!isSendingTx)
          return;
        if(curStatusType == 'withdraw' && withdrawStatusId != id)
          return;
        if(curStatusType == 'unwrapper' && unwrapperStatusId != id)
          return;
        let responseStatus;
        if(curStatusType == 'withdraw') {
          responseStatus = await axios.get(relayerURL + '/v1/withdraw/jobs/' + id, options);
        }
        if(curStatusType == 'unwrapper') {
          responseStatus = await axios.get(relayerURL + '/v1/unwrapper/jobs/' + id, options);
        }
        if (responseStatus.status === 200) {
          const { txHash, status, confirmations, failedReason } = responseStatus.data;

          if(curStatusType == 'withdraw') {
            const msg = lang.t('viaRelayer1') + '#' + lang.t('confirm.block', {num: (confirmations ? confirmations: '0'), total: god.currentChain.L2FinalizationRate});
            god.setLoadingText(msg);
            // god.setLoadingText(`Transaction status ${status}, confirmations: ${confirmations ? confirmations: '0'}`);
          }

          if(curStatusType == 'unwrapper') {
            const msg = lang.t('send.l1.tx') + '#' + lang.t('confirm.block', {num: (confirmations ? confirmations: '0'), total: 3});
            god.setLoadingText(msg);
            // god.setLoadingText(`Transaction status ${status}, confirmations: ${confirmations ? confirmations: '0'}`);
          }
          
          // console.log(`Current job status ${status}, confirmations: ${confirmations}`);
  
          if (status === 'FAILED') {
            throw new Error(status + ' failed reason:' + failedReason);
          }
  
          if (status === 'CONFIRMED') {
            const receipt = await waitForTxReceipt({ txHash });
            // console.log(
            //   `Transaction submitted through the relay. View transaction on block explorer ${txHash}`
            // );
            // console.log('Transaction mined in block', receipt.blockNumber);
            resolve(status);
          }
        }
        if(curStatusType == 'withdraw' && !withdrawStatusId) {
          return;
        }
        if(curStatusType == 'unwrapper' && !unwrapperStatusId) {
          return;
        }
        setTimeout(() => {
          getRelayerStatus(id, relayerURL);
        }, 3000)
      }
  
      getRelayerStatus();
    })
  }

  function waitForTxReceipt({ txHash, attempts = 60, delay = 1000 }) {
    // console.log('txHash', txHash);
    return new Promise((resolve, reject) => {
      const checkForTx = async (txHash, retryAttempt = 0) => {
        let provider;
        if(curStatusType == 'withdraw') {
          provider = new JsonRpcProvider(god.currentChain.gnosisRpcUrl);
        }
        if(curStatusType == 'unwrapper') {
          provider = new JsonRpcProvider(god.currentChain.rpcUrl);
        }
        const result = await provider.getTransactionReceipt(txHash);
        if(curStatusType == 'withdraw') {
          curSignBlockNumber = result.blockNumber;
        }
        if (!result || !result.blockNumber) {
          if (retryAttempt <= attempts) {
            setTimeout(() => checkForTx(txHash, retryAttempt + 1), delay);
          } else {
            reject(new Error('tx was not mined'));
          }
        } else {
          resolve(result);
        }
      }
      checkForTx(txHash);
    })
}

  const store = useLocalStore(() => ({
    isTransactionSent: false,
    confirmedBlocks: 0
  }));

  useEffect(() => {
    if(store.isTransactionSent) {
      god.setLoadingText(lang.t('confirm.block', {num: store.confirmedBlocks, total: god.currentChain.L1FinalizationRate}));
    }
  }, [store.confirmedBlocks]);

  return (
    <div style={{ display: props.isChosen? 'block' : 'none', width: '100%' }}>
    <Wrapper>

      <Text responsive="md" weight="medium" family="Montserrat" color="textPrimary" css={{ mb: '0.5rem' }}>
        Token
      </Text>

      <Section css={{ position: 'relative', marginBottom: 25 }}>
        <div style={{ width: '100%', overflow: 'hidden', cursor: 'pointer' }} className='currencyDropItem' onClick={() => {
            document.getElementById("withdraw-dropdown-content").classList.toggle("show");
          }} >
          <DropItem css={{backgroundColor: '#1E1F21'}}>
            <FlexBox justify="between" css={{width: '100%'}}>
              <FlexBox css={{flex: 1, justifyContent: 'flex-start'}}>
                {
                  god.currentChain.poolList.map((pool) => {
                    if(god.currentPoolIndex === pool.id) {
                      return <>
                        <Icon src={pool.symbolLogo} alt="" css={{ width: '25px', height: '25px', marginRight: '10px' }}></Icon>
                        <Text css={{cursor: 'pointer'}} color="textPrimary" size="small" weight="normal" family="Montserrat">{pool.tokeName}</Text>
                      </>
                    }
                  })
                }
                
              </FlexBox>
              <Icon src="/images/home/btn_dropdown.png" alt="" css={{ width: 24, height: 24 }}></Icon>
            </FlexBox>
          </DropItem>
          <div style={{width: '100%'}}>
            <div style={{position: 'relative'}}>
              <BlurEffect />
            </div>
          </div>
        </div>
        <DropdownBox>
          <Section id="withdraw-dropdown-content" className="currencyDropBox" css={{ zIndex: 1000 }}>
            {
              god.currentChain.poolList.map((pool) => (
                <DropItem
                  onClick={() => {
                    god.requestedPoolIndex = pool.id
                  }}
                  css={{
                    backgroundColor: god.currentPoolIndex === pool.id ? '#5B5B5B' : '#5B5B5B',
                    '&:hover': {
                      ".currencyName": {
                        color: '$textPrimary',
                      },
                      backgroundColor: '#6C6C6C'
                    }
                  }}
                >
                  <Icon src={pool.symbolLogo} alt="" css={{ width: '25px', height: '25px', marginRight: '10px' }}></Icon>
                  <Text cursor="pointer" className="currencyName" color="textPrimary" size="small" weight="normal" family="Montserrat">{pool.tokeName}</Text>
                </DropItem>
            ))}
          </Section>
        </DropdownBox>
      </Section>

      <LpMiningHeader />
      {/* {god.AeolusV2 && <LpMining aeolus={god.AeolusV2} />} */}
      {/* <LpMining aeolus={god.Aeolus} /> */}
      {/* <div style={store.isShowDetail1 ? {background: '#0000006e'} : {}}>
        <TokenTab
          tokenSymbol={god.currentChain.Coin.symbol}
          tabName={god.currentChain.Coin.symbol}
          onSelectTab={() => {
            if (!god.isOnProgress && god.isPublicInfoLoaded && god.isPrivateInfoLoaded)
              store.isShowDetail1 = !store.isShowDetail1;
          }}
          isShowDetail={store.isShowDetail1}
        />
      </div> */}
      {
        god.currentChain.poolList.map((pool) => {
          return god.currentPoolIndex && <WithdrawViewForETH
            indexOfPool={pool.id}
            chainID={god.currentChain.chainId}
            onDeposit={async (depositAmount, feeAmount, recipient, L1feeAmount, rawFee) => {
            if(god.CurrentSet.XRCToken) {
              god.setLoadingText('Sending L1 Tx Fee...');
              god.setLoadingView(true);
              try {
                await god.sendTxFee(recipient, rawFee)
              } catch (error) {
                god.setLoadingView(false);
                hooks.waitLoading({ msg: lang.t('notification'), confirmText: 'Lack of Fee' });
                throw new Error("Error while sending");
              }
            }

            let idx = 0;

            let sumBalance = new BigNumber(0);
            let inputCommitments = [];
            let myUtxoArr = god.CurrentSet.utxoList;

            // console.log('myUtxoArr', myUtxoArr);
            myUtxoArr.map((utxo) => {
              // console.log(utxo.amount.toString());
            })

            while(sumBalance.comparedTo(depositAmount) < 0) {
              if(myUtxoArr[idx].amount.gt(0)) {
                sumBalance = sumBalance.plus(myUtxoArr[idx].amount);
                inputCommitments.push(myUtxoArr[idx].commitment);
              }
              idx ++;
            }

            const restAmount = sumBalance.minus(depositAmount);
            
            let outputData = [];

            if(restAmount.gt(0)) {
              outputData.push({amount: restAmount, pubKey: god.myPublicKey});
            }

            god.setLoadingText(lang.t('Loading.text4'));
            god.setLoadingView(true);

            withdrawStatusId = undefined;
            const relayerStatus = await axios.get(god.CurrentSet.relayer + '/v1/statusWithdraw');
            const { relayer } = relayerStatus.data;
            // console.log('inputCommitments', inputCommitments);
            // console.log('outputData', outputData);
            // console.log('relayer', relayer);

            let response;

            response = await axios.post(god.CurrentSet.relayer + '/v1/submitWithdrawTX', {input: inputCommitments, output: outputData, key: god.myPrivteKey, fee: feeAmount.toString(), recipient: recipient, l1fee: L1feeAmount.toString(), relayer: relayer}, {});
            
            god.setLoadingText(lang.t('viaRelayer1'));

            const withdrawTxId = response.data.id;
            withdrawStatusId = withdrawTxId;
            isSendingTx = true;
            // console.log(withdrawStatusId, 'withdrawStatusId');
            curStatusType = 'withdraw';
            await getStatus(withdrawTxId, god.CurrentSet.relayer, {});
            withdrawStatusId = undefined;
            isSendingTx = false;

            god.setLoadingText(lang.t('send.l1.tx'));
            const encodedData = await getUserSignatureLog(god.currentChain.HomeAMB, god.currentChain.gnosisRpcUrl, curSignBlockNumber);
            // console.log('encodedData', encodedData);

            const signatures = await getSignatures(god.currentChain.AMBBridgeHelper, god.currentChain.gnosisRpcUrl, encodedData);

            // console.log('signatures', signatures);
            if(!signatures)
              return;
            
            response = await axios.post(god.CurrentSet.relayer + '/v1/submitUnwrapperTx', {msg: encodedData, signatures: signatures}, {});

            const unwrapperTxId = response.data.id;
            unwrapperStatusId = unwrapperTxId;
            isSendingTx = true;
            curStatusType = 'unwrapper';
            await getStatus(unwrapperTxId, god.CurrentSet.relayer, {});
            unwrapperStatusId = undefined;
            isSendingTx = false;

            god.setLoadingText(lang.t('update.info'));
            await god.checkCommitmentAndNullifer(god.CurrentSet);

            myUtxoArr = god.CurrentSet.utxoList;

            // console.log('myUtxoArr', myUtxoArr);
            myUtxoArr.map((utxo) => {
              // console.log(utxo.amount.toString());
            })

            god.setLoadingView(false);

            hooks.waitLoading({ msg: lang.t('notification'), confirmText: lang.t('withdraw.success') });

            // response = await axios.post(god.CurrentSet.relayer + '/v1/getProofData', { amount: depositAmount.toString(), key: privateKey });
            // res = response.data;
            // const {args, extData} = res;

            // console.log('args', args);
            // console.log('extData', extData);

            // txData = await generateTxData(ETHMainnetConfig.HorizonPool, ETHMainnetConfig.gnosisRpcUrl, ETHMainnetConfig.BridgeToken, args, extData, depositAmount);
              
            //   god.setLoadingText(lang.t('Loading.text4'));
            //   god.setLoadingView(true);
              
            //   let response = await axios.post(god.CurrentSet.relayer + '/v1/getProofData', { amount: depositAmount.toString(), key: god.myPrivteKey });
            //   let res = response.data;
            //   const {args, extData} = res;

            //   console.log('depositAmount.toString()', depositAmount.toString());
            //   console.log('args', args);
            //   console.log('extData', extData);

            //   const txData = generateTxData(args, extData);

            //   god.setLoadingText(lang.t('send.transaction'));

            //   let txInstance;
            //   try {
            //     txInstance = await god.deposit({amount: depositAmount, data: txData});
            //     store.isTransactionSent = true;
            //   } catch (error) {
            //     god.setLoadingView(false);
            //     store.isTransactionSent = false;
            //     throw new Error("Error while depositing");
            //   }

            //   const provider = new JsonRpcProvider(god.currentChain.rpcUrl);

            //   const handler = async (blockNumber) => {
            //     console.log(blockNumber);
            //     let receipt = await provider.getTransactionReceipt(txInstance.hash);
            //     if(receipt)
            //       store.confirmedBlocks = receipt.confirmations;
            //     else
            //       store.confirmedBlocks = 0;
            //   }

            //   provider.on("block", handler);
              
            //   await txInstance.wait(god.currentChain.L1FinalizationRate ).then((receipt) => {
            //     store.isTransactionSent = false;
            //     provider.removeListener("block", handler);
            //     god.setLoadingText(lang.t('send.bridge'));

            //     let L1MessageId;
            //     console.log('receipt.logs', receipt.logs);
            //     receipt.logs.map((log) => {
            //       if(log.topics.length) {
            //         if(log.topics[0] == god.currentChain.userRequestTopic)
            //         L1MessageId = log.topics[1];
            //       }
            //     });
            //     console.log('messageId', L1MessageId);

            //     const gnosisProvider = new JsonRpcProvider(god.currentChain.gnosisRpcUrl);

            //     const completionFilter = {
            //         address: god.currentChain.HomeAMB.address,
            //         topics: [god.currentChain.AffirmationCompletedTopic]
            //     }
            //     gnosisProvider.on(completionFilter, async (event) => {
            //       if ( event.topics[3] == L1MessageId ) {
            //         god.setLoadingText(lang.t('update.info'));
            //         if(!god.isRegistered) {
            //           await god.checkRegistration(god.myAddress);
            //         }
            //         await god.checkCommitmentAndNullifer();
            //         god.setLoadingView(false);
            //         hooks.waitLoading({ msg: lang.t('notification'), confirmText: lang.t('deposit.success') });
            //       }
            //     })

            //   });
            }}
          />
        })
      } 
      {/* head */}
    </Wrapper>
    </div>
  );
});

const DropdownBox = styled('div', {
  position: 'absolute',
  width: '100%',
  '.currencyDropBox': {
    width: '100%',
    position: 'absolute',
    display: 'none',
    borderRadius: '12px',
    cursor: 'pointer'
  }
});

const DropItem = styled('div', {
  width: '100%',
  height: '50px',
  backgroundColor: '#5B5B5B',
  border: '1px solid #3C3C3C',
  borderRadius: '12px',
  padding: '0 9px 0 11px',
  boxSizing: 'border-box',
  display: 'flex',
  alignItems: 'center',
  variants: {
    color: {
      normal: {
        '&:hover': {
          backgroundColor: '$bg3'
        }
      }
    }
  }
});

export const BlurEffect = styled('div', {
  position: 'absolute',
  width: '60%',
  height: '25px',
  left: '20%', // Center horizontally
  top: '-12px ', // Center vertically
  background: '#5729B8',
  opacity: '0.7',
  filter: 'blur(20px)',
  transform: 'matrix(1, 0, 0, -1, 0, 0)'
});