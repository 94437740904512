import React, { useState } from 'react';
import { styled } from '../../modules/index';
import { Icon, Text } from '../../modules/globalStyle';

interface IComponentProps {
  name: string;
  id: string; // Assuming id is a string
  isActive: boolean; // Define the possible values for isActive
  onClick: () => void; // Function type for onClick
  onMouseEnter: () => void; // Function type for onMouseEnter
  onMouseLeave: () => void; // Function type for onMouseLeave
}

export const TabButton = styled('div', {
  backgroundSize: 'contain',
  backgroundRepeat: 'no-repeat',
  width: '100px',
  height: '46px',
  variants: {
    status: {
      active: {
        backgroundImage: "url('/images/home/active_tab.svg')",
        
      },
      idle: {
        backgroundImage: "url('/images/home/idle_tab.svg')",
      }
    }
  },
  marginRight: '15px',
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center'
});

export const TabButtonItem = (props: IComponentProps) => {
  const { name, id, isActive, onClick, onMouseEnter, onMouseLeave } = props;
  return (
    <TabButton id={id} onClick={onClick} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} css={{'@md': { width: '122px', marginRight: '30px' }}} status={ isActive ? 'active' : 'idle' }>
        <Text cursor="pointer" responsive="md" color="white" weight="medium" family="Montserrat" css={{ flex: 1, textAlign: 'center', fontSize: '14' }}>{name}</Text>
    </TabButton>
  );
};
