import { JsonRpcProvider } from '@ethersproject/providers';
import axios from 'axios';
import { ethers } from 'ethers';

const abi = new ethers.utils.AbiCoder()

function encodeDataForBridge({ proof, extData }) {
  return abi.encode(
    [
      'tuple(bytes proof,bytes32 root,bytes32[] inputNullifiers,bytes32[2] outputCommitments,uint256 publicAmount,bytes32 extDataHash)',
      'tuple(address recipient,int256 extAmount,address relayer,uint256 fee,bytes encryptedOutput1,bytes encryptedOutput2,bool isL1Withdrawal,uint256 l1Fee)',
    ],
    [proof, extData],
  )
}

export async function getKeypairLog (from, L1HelperContract, rpcUrl, relayerURL, pubKey) {
    let stBlock, endBlock;
    stBlock = (from == 0 ? L1HelperContract.deployedBlock : from);
    let provider = new JsonRpcProvider(rpcUrl);

    let tornadoPool = new ethers.Contract(L1HelperContract.address, L1HelperContract.abi, provider);

    // console.log('tornadoPool', tornadoPool);

    const filter = tornadoPool.filters.PublicKey()

    // console.log('stBlock', stBlock);
    endBlock = (await provider.getBlock()).number
    // console.log('endBlock', endBlock);
    
    
    let fromBlock = stBlock
    let toBlock = fromBlock + 999

    let totEvents = [];
    
    while(toBlock < endBlock) {

        // console.log('fromBlock', fromBlock)
        // console.log('toBlock', toBlock)
        
        const subEvents = await getSafeLog(tornadoPool, filter, fromBlock, toBlock);
        let tempEvents = [];
        for (const event of subEvents) {
            tempEvents.push({blockNumber: event.blockNumber, address: event.args.owner, publicKey: event.args.key});
        }
        
        totEvents = totEvents.concat(tempEvents);

        await axios.post(relayerURL + '/v1/updateKeypair', {
            fromBlockNumber: fromBlock,
            toBlockNumber: toBlock,
            keypairArr: tempEvents
        }, {});

        fromBlock = toBlock + 1;
        toBlock = fromBlock + 999
    }

    toBlock = endBlock;

    // console.log('fromBlock', fromBlock)
    // console.log('toBlock', toBlock)
    
    const subEvents = await getSafeLog(tornadoPool, filter, fromBlock, toBlock);
    let tempEvents = [];
    for (const event of subEvents) {
        tempEvents.push({blockNumber: event.blockNumber, address: event.args.owner, publicKey: event.args.key});
    }
    totEvents = totEvents.concat(tempEvents);
    await axios.post(relayerURL + '/v1/updateKeypair', {
        fromBlockNumber: fromBlock,
        toBlockNumber: toBlock,
        keypairArr: tempEvents
    }, {});
    
    let idx = -1;
    // console.log(totEvents, pubKey);
    totEvents.map((event, index) => {
        if( event.publicKey == pubKey ) {
            idx = index;
        }
    });
    if(idx < 0) {
        return undefined;
    }
    else {
        return totEvents[idx];
    }
}
export async function getCommitmentAndNullifierLog (from_commitment, from_nullifier, HorizonPoolContract, rpcUrl, relayerURL) {
    if(from_commitment != from_nullifier)
        return;
    let stBlock, endBlock;
    stBlock = (from_commitment == 0 ? HorizonPoolContract.deployedBlock : from_commitment);
    let provider = new JsonRpcProvider(rpcUrl);

    let tornadoPool = new ethers.Contract(HorizonPoolContract.address, HorizonPoolContract.abi, provider);
    const filter_commitment = tornadoPool.filters.NewCommitment()
    const filter_nullifier = tornadoPool.filters.NewNullifier()

    // console.log('stBlock', stBlock);
    endBlock = (await provider.getBlock()).number
    
    let fromBlock = stBlock
    let toBlock = fromBlock + 500
    
    while(toBlock < endBlock) {
            
        // console.log('finding log started...', fromBlock, toBlock);
        const subEvents_commitment = await getSafeLog(tornadoPool, filter_commitment, fromBlock, toBlock);
        const subEvents_nullifier = await getSafeLog(tornadoPool, filter_nullifier, fromBlock, toBlock);
        // console.log('subEvents_commitment', subEvents_commitment);
        // console.log('subEvents_nullifier', subEvents_nullifier);
        // console.log('finding log finished...');
        let tempEvents_commitment = [], tempEvents_nullifier = [];
        for (const event of subEvents_commitment) {
            tempEvents_commitment.push({blockNumber: event.blockNumber, encryptedOutput: event.args.encryptedOutput, commitment: event.args.commitment, index: event.args.index.toNumber()});
        }
        for (const event of subEvents_nullifier) {
            tempEvents_nullifier.push({blockNumber: event.blockNumber, nullifier: event.args.nullifier});
        }

        await axios.post(relayerURL + '/v1/updateCommitmentAndNullifier', {
            fromBlockNumber: fromBlock,
            toBlockNumber: toBlock,
            commitmentArr: tempEvents_commitment,
            nullifierArr: tempEvents_nullifier
        }, {});

        fromBlock = toBlock + 1;
        toBlock = fromBlock + 500

        // console.log('fromBlock', fromBlock)
        // console.log('toBlock', toBlock)
    }

    toBlock = endBlock;
    
    // console.log('finding log started...', fromBlock, toBlock);
    const subEvents_commitment = await getSafeLog(tornadoPool, filter_commitment, fromBlock, toBlock);
    const subEvents_nullifier = await getSafeLog(tornadoPool, filter_nullifier, fromBlock, toBlock);
    // console.log('subEvents_commitment', subEvents_commitment);
    // console.log('subEvents_nullifier', subEvents_nullifier);
    // console.log('finding log finished...');
    let tempEvents_commitment = [], tempEvents_nullifier = [];
    for (const event of subEvents_commitment) {
        tempEvents_commitment.push({blockNumber: event.blockNumber, encryptedOutput: event.args.encryptedOutput, commitment: event.args.commitment, index: event.args.index.toNumber()});
    }
    for (const event of subEvents_nullifier) {
        tempEvents_nullifier.push({blockNumber: event.blockNumber, nullifier: event.args.nullifier});
    }

    await axios.post(relayerURL + '/v1/updateCommitmentAndNullifier', {
        fromBlockNumber: fromBlock,
        toBlockNumber: toBlock,
        commitmentArr: tempEvents_commitment,
        nullifierArr: tempEvents_nullifier
    }, {});
}

export function generateTxData(args, extData) {
    const onTokenBridgedData = encodeDataForBridge({
        proof: args,
        extData,
    })
    return onTokenBridgedData;
}

export async function getUserSignatureLog(HomeAMB, rpcUrl, blockNumber) {
    let provider = new JsonRpcProvider(rpcUrl);
    let HomeAMBContract = new ethers.Contract(HomeAMB.address, HomeAMB.abi, provider);
    const filter = HomeAMBContract.filters.UserRequestForSignature();
    const sigData = await getSafeLog(HomeAMBContract, filter, blockNumber);
    const { encodedData } = sigData[0].args;
    return encodedData;
}

export async function getSignatures(bridgeHelper, rpcUrl, message) {
    let provider = new JsonRpcProvider(rpcUrl);
    let HomeAMBContract = new ethers.Contract(bridgeHelper.address, bridgeHelper.abi, provider);
    // console.log('HomeAMBContract', HomeAMBContract);
    // console.log('message', message);
    let signatures = null;
    
    const getSign = async () => {
        try {
            signatures = await HomeAMBContract.getSignatures(message);
            // console.log('signatures', signatures);
        }
        catch (error) {
            // console.log(error);
        }
    };

    while(!signatures) {
        // console.log('try');
        await getSign();
        if(!signatures) {
            // console.log('retry');
            await new Promise(r => setTimeout(r, 6000));
        }
    }

    return signatures;
}

export async function getSafeLog(contract, query, fromBlock, toBlock) {
    let isSafe = false;
    let res;

    const getLogFunc = async () => {
        try {
            if(toBlock) {
                res = await contract.queryFilter(query, fromBlock, toBlock);
            }
            else {
                res = await contract.queryFilter(query, fromBlock);
            }
            isSafe = true;
        } catch(e) {
            // console.log('getting log error', e);
        }
    };

    while(!isSafe) {
        // console.log('try getting log');
        await getLogFunc();
        if(!isSafe) {
            // console.log('retry getting log');
            await new Promise(r => setTimeout(r, 300));
        }
    }

    return res;
}

export async function getPoolBalance(token, rpcUrl, poolAddress) {
    let provider = new JsonRpcProvider(rpcUrl);
    let tokenContract = new ethers.Contract(token.address, token.abi, provider);
    let res = await tokenContract.balanceOf(poolAddress);
    return res;
}