import React from 'react';
import { styled } from '../../modules/index';
import { Icon, Text, FlexBox } from '../../modules/globalStyle';
import { DialogWrapper } from '../Dialog/index';
import { observer, useLocalStore } from 'mobx-react-lite';
import { useStore } from '../../store/index';
import { injectedConnector, walletconnectConnector } from '../../lib/web3-react-bsc';
import { useWeb3React } from '@web3-react/core';
import { isMobile } from "react-device-detect";
import { hooks } from '../../lib/hooks';

const WalletBox = styled('div', {
  padding: '1.5rem 0 0.5rem 0',
  borderTop: '1px solid $borderPrimary',
  borderBottom: '1px solid $borderPrimary'
});

const WalletItem = styled('div', {
  backgroundColor: '$bg1',
  p: '1rem',
  mb: '1rem'
});

function getIsMetaMask(): boolean {
  return window.ethereum?.isMetaMask ?? false
}

function getIsMetaMaskBrowser(): boolean {
  return isMobile && getIsMetaMask();
}

export const EthConnectWallet = observer(() => {
  const { god, lang } = useStore();
  const { activate, error, deactivate } = useWeb3React();

  const store = useLocalStore(() => ({
    get visible() {
      return god.eth.connector.showConnector;
    },
    close() {
      god.eth.connector.showConnector = false;
    },
    connectInejct() {
      activate(injectedConnector);
      god.eth.connector.latestProvider.save('inject');
    },
    connectWalletConnect() {
      activate(walletconnectConnector);
      god.eth.connector.latestProvider.save('walletconnect');
    },
    connectWithKey() {
      this.close();
      hooks.waitPendingPool();
    },
  }));

  const content = (
    <WalletBox>
      { ( !isMobile || getIsMetaMaskBrowser() ) &&
        <WalletItem onClick={store.connectInejct} css={{ cursor: 'pointer' }} >
          <FlexBox justify="between" >
            <Text size="small" weight="semibold" color="white" css={{ cursor: 'pointer' }}>
              {lang.t('metamask')}
            </Text>
            <Icon src="/images/metamask.svg"></Icon>
          </FlexBox>
        </WalletItem>
      }
      { 
      <WalletItem onClick={store.connectWithKey} css={{ cursor: 'pointer' }}  >
        <FlexBox justify="between" >
          <Text size="small" weight="semibold" color="white" css={{ cursor: 'pointer' }}>
            {lang.t('shielded.key')}
          </Text>
          <Icon src="/images/key.svg"></Icon>
        </FlexBox>
      </WalletItem>
      }
    </WalletBox>
  );
  return <DialogWrapper title={lang.t('connect.to.wallet')} size="base" content={content} visible={store.visible} close={store.close} />;
});