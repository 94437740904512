import { TokenState, LPTokenState } from '../store/lib/TokenState';
import { ContractState, AeolusContractState } from '../store/lib/ContractState';
import L1HelperABI from '../../public/contracts/L1Helper.json';
import XRC20ABI from '../../public/contracts/XRC20.json';
import HorizonABI from '../../public/contracts/HorizonPool.json';
import AMBABI from '../../public/contracts/AMBABI.json';
import BridgeHelperABI from '../../public/contracts/BridgeHelperABI.json';
import { PoolState } from '../store/lib/PoolState';
import { ChainState } from '../store/lib/ChainState';
import BigNumber from 'bignumber.js';
import L1Unwrapper from '../../public/contracts/L1Unwrapper.json';


export const ETHMainnetConfig = new ChainState({
  name: 'ETH Mainnet',
  path: '/eth',
  chainId: 1,
  baseRpcUrl: 'https://mainnet.infura.io/v3/',
  rpcUrl: 'https://dawn-sly-spree.quiknode.pro/c6e5fd5873c3b1f6c053b1f1e12861165efc7163/',
  gnosisRpcUrl: 'https://indulgent-winter-slug.xdai.quiknode.pro/a4d060ac51aca087311ae608ddfb07a540b6de28/',
  explorerURL: 'https://etherscan.io',
  explorerName: 'EtherScan',
  swapName: 'UniSwap',
  swapURL: 'https://app.uniswap.org/#/swap/?inputCurrency=ETH&outputCurrency=0x96884fcAAc082Db4B32601aDA5b177FD6cBFFA88',
  APIURL: 'https://analytics-eth.cyclone.xyz/query',
  relayer: 'https://relayer-eth.zklock.io',
  userRequestTopic: '0x482515ce3d9494a37ce83f18b72b363449458435fafdd7a53ddea7460fe01b58',
  AffirmationCompletedTopic: '0xe194ef610f9150a2db4110b3db5116fd623175dca3528d7ae7046a1042f84fe7',
  L2UserRequestTopic: '0x520d2afde79cbd5db58755ac9480f81bc658e5c517fcae7365a3d832590b0183',
  L1FinalizationRate: 130,
  L2FinalizationRate: 100,

  Coin: new TokenState({
    symbol: 'ETH',
    decimals: 18
  }),
  MultiCall: new ContractState({
    address: '0xeefba1e63905ef1d7acba5a8513c70307c1ce441'
  }),
  CYCToken: new TokenState({
    address: '0x96884fcAAc082Db4B32601aDA5b177FD6cBFFA88',
    abi: XRC20ABI,
    decimals: 18,
    symbol: 'ZKLK'
  }),
  L1Helper: new ContractState({
    address: '0x7ddd121Eb223940F84E69865D3E269600dd25320',
    abi: L1HelperABI,
    deployedBlock: 20438441
  }),
  L1Unwrapper: new ContractState({
    address: '0xd163d223534e7a242edD12eA0f5F2bd33A13D115',
    abi: L1Unwrapper,
  }),
  HomeAMB: new ContractState({
    address: '0x75Df5AF045d91108662D8080fD1FEFAd6aA0bb59',
    abi: AMBABI,
  }),
  AMBBridgeHelper: new ContractState({
    address: '0x7d94ece17e81355326e3359115D4B02411825EdD',
    abi: BridgeHelperABI,
  }),
  pools: {
    'ETH': new PoolState({
      id: 'ETH',
      symbolLogo: '/images/home/eth_token.svg',
      tokeName: 'Ethereum',
      set: 'ZkMix (0.05 ETH - 100 ETH)',
      maxDepositAmount: new BigNumber('100e+18'),
      minDepositAmount: new BigNumber('0.05e+18'), // ######## temporaly min = 0.02 ########
      HorizonPool: new ContractState({
        address: '0x1Feb9218C1d6E43D2588418a23E5300172E52B9C',
        abi: HorizonABI,
        deployedBlock: 35273585
      }),
      BridgeToken: new TokenState({
        address: '0x6A023CCd1ff6F2045C3309768eAd9E68F978f6e1',
        abi: XRC20ABI,
        decimals: 18,
        symbol: 'WETH'
      }),
      symbol: 'ETH',
      relayer: 'https://relayer-eth.zklock.io',
      order: 0,
    }),
    'ZKLK': new PoolState({
      id: 'ZKLK',
      symbolLogo: '/images/home/zklk_logo.svg',
      tokeName: 'ZkLock',
      set: 'ZkMix (1,000 ZKLK - 100,000,000 ZKLK)',
      maxDepositAmount: new BigNumber('100000000e+18'),
      minDepositAmount: new BigNumber('1000e+18'),
      HorizonPool: new ContractState({
        address: '0xfC1D03130B988085B6c72beF39ed44Df089d2566',
        abi: HorizonABI,
        deployedBlock: 35321129
      }),
      XRCToken: new TokenState({
        address: '0x96884fcAAc082Db4B32601aDA5b177FD6cBFFA88',
        abi: XRC20ABI,
        decimals: 18,
        symbol: 'ZKLK'
      }),
      BridgeToken: new TokenState({
        address: '0xa1e1b3d8bf1bce1b984efd1c21b62cfb623222c0',
        abi: XRC20ABI,
        decimals: 18,
        symbol: 'ZKLK'
      }),
      symbol: 'ZKLK',
      relayer: 'https://relayer-zklk.zklock.io',
      order: 1,
    }),
  }
}); 
