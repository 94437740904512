import React, { useEffect, useState } from 'react';
import { observer, useLocalStore } from 'mobx-react-lite';
import { Wrapper, Icon, FlexBox, Text, BorderBox, Button, Section, DropBox } from '../../../modules/globalStyle';
import { useStore } from '../../../store';
import { LpMiningHeader } from '../../../components/LpMiningHeader';
import { DepositViewForBSC } from '../../../components/DepositViewForBSC';
import axios from 'axios';
import { generateTxData } from '../../../store/lib/GetLog';
import { JsonRpcProvider } from '@ethersproject/providers';
import { hooks } from '../../../lib/hooks';
import { styled } from '../../../modules';

export const Deposit = observer((props) => {
  const { lang, god, dev, base } = useStore();

  const store = useLocalStore(() => ({
    isTransactionSent: false,
    confirmedBlocks: 0
  }));

  useEffect(() => {
    if(store.isTransactionSent) {
      god.setLoadingText(lang.t('confirm.block', {num: store.confirmedBlocks, total: god.currentChain.L1FinalizationRate}));
    }
  }, [store.confirmedBlocks]);

  return (
    <div style={{ display: props.isChosen? 'block' : 'none', width: '100%' }}>
    <Wrapper>
    
      <Text responsive="md" weight="medium" family="Montserrat" color="textPrimary" css={{ mb: '0.5rem' }}>
        Token
      </Text>

      <Section css={{ position: 'relative', marginBottom: 25 }}>
        <div style={{ width: '100%', overflow: 'hidden', cursor: 'pointer' }} className='currencyDropItem' onClick={() => {
            document.getElementById("deposit-dropdown-content").classList.toggle("show");
          }} >
          <DropItem css={{backgroundColor: '#1E1F21'}}>
            <FlexBox justify="between" css={{width: '100%'}}>
              <FlexBox css={{flex: 1, justifyContent: 'flex-start'}}>
                {
                  god.currentChain.poolList.map((pool) => {
                    if(god.currentPoolIndex === pool.id) {
                      return <>
                        <Icon src={pool.symbolLogo} alt="" css={{ width: '25px', height: '25px', marginRight: '10px' }}></Icon>
                        <Text css={{cursor: 'pointer'}} color="textPrimary" size="small" weight="normal" family="Montserrat">{pool.tokeName}</Text>
                      </>
                    }
                  })
                }
                
              </FlexBox>
              <Icon src="/images/home/btn_dropdown.png" alt="" css={{ width: 24, height: 24 }}></Icon>
            </FlexBox>
          </DropItem>
          <div style={{width: '100%'}}>
            <div style={{position: 'relative'}}>
              <BlurEffect />
            </div>
          </div>
        </div>
        <DropdownBox>
          <Section id="deposit-dropdown-content" className="currencyDropBox" css={{ zIndex: 1000 }}>
            {
              god.currentChain.poolList.map((pool) => (
                <DropItem
                  onClick={() => {
                    god.requestedPoolIndex = pool.id
                  }}
                  css={{
                    backgroundColor: god.currentPoolIndex === pool.id ? '#5B5B5B' : '#5B5B5B',
                    '&:hover': {
                      ".currencyName": {
                        color: '$textPrimary',
                      },
                      backgroundColor: '#6C6C6C'
                    }
                  }}
                >
                  <Icon src={pool.symbolLogo} alt="" css={{ width: '25px', height: '25px', marginRight: '10px' }}></Icon>
                  <Text cursor="pointer" className="currencyName" color="textPrimary" size="small" weight="normal" family="Montserrat">{pool.tokeName}</Text>
                </DropItem>
            ))}
          </Section>
        </DropdownBox>
      </Section>

      <LpMiningHeader />
      {/* {god.AeolusV2 && <LpMining aeolus={god.AeolusV2} />} */}
      {/* <LpMining aeolus={god.Aeolus} /> */}
      {/* <div style={store.isShowDetail1 ? {background: '#0000006e'} : {}}>
        <TokenTab
          tokenSymbol={god.currentChain.Coin.symbol}
          tabName={god.currentChain.Coin.symbol}
          onSelectTab={() => {
            if (!god.isOnProgress && god.isPublicInfoLoaded && god.isPrivateInfoLoaded)
              store.isShowDetail1 = !store.isShowDetail1;
          }}
          isShowDetail={store.isShowDetail1}
        />
      </div> */}
      {
        god.currentChain.poolList.map((pool) => {
          return god.currentPoolIndex && <DepositViewForBSC
            indexOfPool={pool.id}
            chainID={god.currentChain.chainId}
            onApproveXRC={ async (token, value)=> {
              try {
                await god.approve({token, spender: god.currentChain.L1Helper.address, value})
              } catch (error) {
                  throw new Error("Error while approving");
              }
            }}
            onDeposit={async (depositAmount, totAmount) => {
              god.setLoadingText(lang.t('Loading.text4'));
              god.setLoadingView(true);
              
              let response = await axios.post(god.CurrentSet.relayer + '/v1/getProofData', { amount: depositAmount.toString(), key: god.myPrivteKey });
              let res = response.data;
              const {args, extData} = res;

              // console.log('depositAmount.toString()', depositAmount.toString());
              // console.log('args', args);
              // console.log('extData', extData);

              const txData = generateTxData(args, extData);

              god.setLoadingText(lang.t('send.transaction'));

              let txInstance;
              try {
                txInstance = await god.deposit({amount: totAmount, data: txData});
                store.isTransactionSent = true;
              } catch (error) {
                god.setLoadingView(false);
                store.isTransactionSent = false;
                throw new Error("Error while depositing");
              }

              const provider = new JsonRpcProvider(god.currentChain.rpcUrl);

              const handler = async (blockNumber) => {
                // console.log(blockNumber);
                let receipt = await provider.getTransactionReceipt(txInstance.hash);
                if(receipt)
                  store.confirmedBlocks = receipt.confirmations;
                else
                  store.confirmedBlocks = 0;
              }

              provider.on("block", handler);
              
              await txInstance.wait(god.currentChain.L1FinalizationRate ).then((receipt) => {
                store.isTransactionSent = false;
                provider.removeListener("block", handler);
                god.setLoadingText(lang.t('send.bridge'));

                let L1MessageId;
                // console.log('receipt.logs', receipt.logs);
                receipt.logs.map((log) => {
                  if(log.topics.length) {
                    if(log.topics[0] == god.currentChain.userRequestTopic)
                    L1MessageId = log.topics[1];
                  }
                });
                // console.log('messageId', L1MessageId);

                const gnosisProvider = new JsonRpcProvider(god.currentChain.gnosisRpcUrl);

                const completionFilter = {
                    address: god.currentChain.HomeAMB.address,
                    topics: [god.currentChain.AffirmationCompletedTopic]
                }
                gnosisProvider.on(completionFilter, async (event) => {
                  if ( event.topics[3] == L1MessageId ) {
                    god.setLoadingText(lang.t('update.info'));
                    if(!god.isRegistered) {
                      await god.checkRegistration(god.myAddress);
                    }
                    await god.checkCommitmentAndNullifer(god.CurrentSet);
                    god.setLoadingView(false);
                    hooks.waitLoading({ msg: lang.t('notification'), confirmText: lang.t('deposit.success') });
                  }
                })

              });
            }}
          />
        })
      } 
      {/* head */}
    </Wrapper>
    </div>
  );
});

const DropdownBox = styled('div', {
  position: 'absolute',
  width: '100%',
  '.currencyDropBox': {
    width: '100%',
    position: 'absolute',
    display: 'none',
    borderRadius: '12px',
    cursor: 'pointer'
  }
});

const DropItem = styled('div', {
  width: '100%',
  height: '50px',
  backgroundColor: '#5B5B5B',
  border: '1px solid #3C3C3C',
  borderRadius: '12px',
  padding: '0 9px 0 11px',
  boxSizing: 'border-box',
  display: 'flex',
  alignItems: 'center',
  variants: {
    color: {
      normal: {
        '&:hover': {
          backgroundColor: '$bg3'
        }
      }
    }
  }
});

export const BlurEffect = styled('div', {
  position: 'absolute',
  width: '60%',
  height: '25px',
  left: '20%', // Center horizontally
  top: '-12px ', // Center vertically
  background: '#5729B8',
  opacity: '0.7',
  filter: 'blur(20px)',
  transform: 'matrix(1, 0, 0, -1, 0, 0)'
});