import React, { useEffect, useState } from 'react';
import { observer, useLocalStore } from 'mobx-react-lite';
import { InnerWrapper, Button, FlexBox, Text, antdPopoverStyle, Icon, Section } from '../../modules/globalStyle';
import { css, styled } from '../../modules/index';
import { HomeTop } from './Top/index';
import { CycFooter } from '../../components/CycFooter';
import { OnLoading } from '../../components/OnLoading/index';
import { useStore } from '../../store';
import { eventBus } from '../../lib/event';
import { WalletInfo } from '../../components/WalletInfo';
import { CheckNote } from '../../components/CheckNote';
import { Deposit } from './Deposit/index';
import { Withdraw } from './Withdraw/index';
import { TransactionLog } from './TransactionRecord/index';
import { Transfer } from './Transfer/index';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import CountUp from 'react-countup';

import { NoticeItem } from '../../components/Notice';
import { TabButtonItem } from '../../components/TabButton';

const SwitchItem = styled('div', {
  width: 24,
  height: 24,
  lineHeight: '24px',
  cursor: 'pointer',
  '.name': {
    textAlign: 'center'
  },
  variants: {
    bg: {
      active: {
        backgroundColor: '$primaryOpacity'
      },
      normal: {
        backgroundColor: 'transparent'
      }
    }
  }
});

export const HomePage = observer(() => {
  antdPopoverStyle();
  const { lang, base, god, setting } = useStore();
  const [tabKey, setTabKey] = useState(1);
  
  const store = useLocalStore(() => ({
    anchorEl: null,
    curSelectedId: 0,

    get tabConfig() {
      return [
        { name: lang.t('Deposit'), id: 1 },
        { name: lang.t('Transfer'), id: 2 },
        { name: lang.t('Withdraw'), id: 3 },

        // { name: lang.t('note'), id: 3, count: transaction.currentTransactions.length }
      ];
    },

    get open() {
      return Boolean(this.anchorEl);
    },

    toggleSwtich() {
      setting.approveMax.save(!setting.approveMax.value);
    },

    setAnchorEl(element) {
      this.anchorEl = element;
    },

    handlePopoverOpen (event) {
      this.curSelectedId = event.currentTarget.getAttribute('id');
      this.setAnchorEl(event.currentTarget);
    },

    handlePopoverClose () {
      this.setAnchorEl(null);
    },

  }));

  return (
    <div
      className={styles.home.className}
      style={{ overflow: base.pendingPool.visible ? 'hidden' : 'auto', height: base.pendingPool.visible ? '100vh' : 'auto' }}
    >
      {/* top header content */}
      <HomeTop />

      <article className={styles.homeContent.className} style={{ flex: 1 }}>
        {/* Deposit  Withdraw  Note*/}
        <InnerWrapper css={{ mt: '2.5rem', mb: '3.75rem', '@md': { mb: '4rem' } }}>

          <NoticeItem
            content={
              <div>
                {lang.t('notice.txt1')}&nbsp;<br />
                {lang.t('notice.txt2')}
                <a>shielded keys</a>&nbsp;
                {lang.t('notice.txt2_next')}
              </div>
            }
          />

          <InfoCard>
            <FlexBox justify="between" responsive="rc" css={{ alignItems: 'stretch', '@md': { alignItems: 'center' } }}>
              <Section css={{ mb: '3rem', '@md': { mb: 0 } }}>
                <Text size="small" weight="medium" family="Montserrat" color="textPrimary" css={{ mb: '0.5rem' }} responsive="small">
                  <span style={{marginRight: 12}}>{lang.t('tvl')}</span>
                  {base.tvl ? (
                    <CountUp
                      end={base.tvl}
                      duration={1}
                      decimals={2}
                      preserveValue
                      formattingFn={(e) => `$${e.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`}
                    />
                  ) : (
                    '...'
                  )}
                </Text>
                <Text size="small" weight="medium" family="Montserrat" color="light" responsive="small">
                  <span>{lang.t('token.price')}: $</span>
                  <CountUp end={base.m87PriceUSD.format} duration={1} decimals={10} preserveValue />
                </Text>
              </Section>
              <Section>
                <InnerInfoCard css={{mb: 10}}>
                  <FlexBox justify="end" onClick={(e) => god.addCYCToMetamask()}>
                    <Text size="mini" color="light" hover="light" cursor="pointer">
                      {lang.t('add.metamask')}
                    </Text>
                    <Icon src="/images/home/metamask_logo.png" css={{ marginLeft: '0.8rem' }} alt="" />
                  </FlexBox>
                </InnerInfoCard>
                <a href={god.currentNetwork.chains[1].swapURL} target="_blank">
                  <InnerInfoCard>
                    <FlexBox justify="end">
                      <Text size="mini" color="light" hover="light" cursor="pointer">
                        {lang.t('add.to', { target: god.currentNetwork.chains[1].swapName })}
                      </Text>
                      <Icon src={`/images/home/${god.currentNetwork.chains[1].swapName.toLowerCase()}.png`} css={{ marginLeft: '0.8rem' }} alt="" />
                    </FlexBox>
                  </InnerInfoCard>
                </a>
              </Section>
            </FlexBox>
          </InfoCard>

          <FlexBox justify="between" responsive="rc" css={{ marginBottom: 15, alignItems: 'stretch', '@md': { alignItems: 'center' } }}>
            <FlexBox>
              {store.tabConfig.map((item) => {
                return (
                  <div>
                    {/* <Button
                      key={item.name}
                      id={item.id}
                      size="medium"
                      responsive="medium"
                      color={tabKey == item.id ? 'active' : 'normal'}
                      onClick={() => setTabKey(item.id)}
                      onMouseEnter={store.handlePopoverOpen}
                      onMouseLeave={store.handlePopoverClose}
                    >
                      <span>{item.name} </span>
                      {item.count > 0 && <span>({item.count})</span>}
                    </Button> */}

                    <TabButtonItem
                      name={item.name}
                      id={item.id}
                      isActive={tabKey == item.id}
                      onClick={() => setTabKey(item.id)}
                      onMouseEnter={store.handlePopoverOpen}
                      onMouseLeave={store.handlePopoverClose}
                    />
                    
                    <Popover
                      id="mouse-over-popover"
                      color='primary'
                      sx={{
                        pointerEvents: 'none',
                        width: '100%',
                        maxWidth: store.curSelectedId == 1 ? '1000px' : (store.curSelectedId == 2 ? '1200px' : '1400px')
                      }}
                      open={store.open}
                      anchorEl={store.anchorEl}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                      }}
                      onClose={store.handlePopoverClose}
                      disableRestoreFocus
                    >
                      <Typography color='white' style={{backgroundColor: '#121212'}} sx={{ p: 1 }}>
                        <Text responsive="sm" weight="medium" family="Montserrat" color="white" >{
                          store.curSelectedId == 1 ? lang.t('deposit.note') : (store.curSelectedId == 2 ? lang.t('transfer.note') : lang.t('withdraw.note'))
                        }</Text>
                      </Typography>
                    </Popover>
                  </div>
                );
              })}
            </FlexBox>
            {/* <FlexBox justify="end" items="center" css={{ mb: '3rem', paddingTop: '50px' }}>
              <Text size="small" color="light" cursor="none" align="right">
                {lang.t('use.max.approve')}
              </Text>
              <FlexBox justify="between" css={{ border: '1px solid $borderPrimary', width: '54px', height: '28px', ml: '1rem', px: '1px' }}>
                <SwitchItem bg={setting.approveMax.value ? 'active' : 'normal'} onClick={store.toggleSwtich}>
                  <Text color={setting.approveMax.value ? 'primary' : 'light'} size="mini" className="name" cursor="pointer">
                    On
                  </Text>
                </SwitchItem>
                <SwitchItem bg={setting.approveMax.value ? 'normal' : 'active'} onClick={store.toggleSwtich}>
                  <Text color={setting.approveMax.value ? 'light' : 'primary'} size="mini" className="name" cursor="pointer">
                    Off
                  </Text>
                </SwitchItem>
              </FlexBox>
            </FlexBox> */}
            {/* <FlexBox items="start" css={{ justifyContent: 'flex-end', '@md': { justifyContent: 'flex-start' } }}>
              <Text size="small" color="primary" cursor="none" align="right">
                {lang.t('yourIp')}: {base.ip} <br /> {base.geo}
              </Text>
              <Popover
                placement="top"
                trigger="hover"
                content={
                  <Text size="md" color="white" css={{ width: 200 }}>
                    {lang.t('yout.ip.tips')}
                  </Text>
                }
              >
                <Icon src="/images/home/info_icon.png" css={{ ml: '0.8rem' }} />
              </Popover>
            </FlexBox> */}
          </FlexBox>
          
          {/* chain tab */}
          {/* <ChainNav /> */}

          <MainCard>
            <Deposit isChosen={tabKey == 1} /> 
            <Transfer isChosen={tabKey == 2} />
            <Withdraw isChosen={tabKey == 3} />
          </MainCard>
        </InnerWrapper>

        {/* onLoading */}
        <OnLoading
          visible={base.loading.visible}
          confirmText={base.loading.confirmText}
          showLoading={base.loading.showLoading}
          showConfirm={base.loading.showConfirm}
          text={base.loading.msg}
          showCancel={base.loading.showCancel}
          close={() => base.closeLoading()}
          onConfirm={() => eventBus.emit('loading.confirm')}
          onCancel={() => eventBus.emit('loading.cancel')}
        />
        {base.pendingPool.visible && (
          <CheckNote
            visible={base.pendingPool.visible}
            note={base.pendingPool.note}
            close={() => eventBus.emit('pendingPool.cacel')}
            onConfirm={() => eventBus.emit('pendingPool.confirm')}
            onCancel={() => eventBus.emit('pendingPool.cacel')}
          />
        )}
        {/* WaleltInfo */}
        <WalletInfo />
      </article>
      <CycFooter />
    </div>
  );
});

const styles = {
  home: css({
    minHeight: '100vh',
    backgroundImage: `url(/images/home/backgroundImage.png)`,
    fontFamily: 'IBM Plex Sans',
    display: 'flex',
    flexDirection: 'column',
    'position': 'relative',
    'background-repeat': 'repeat',
    'background-position': '50% 50%',
    'background-size': 'cover',
  }),
  homeContent: css({
    padding: '0 0 10rem 0'
  })
};

export const InfoCard = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: '20px',
  gap: '20px',
  width: '100%',
  background: '#151617',
  border: '1px solid #3C3C3C',
  borderRadius: '16px',
  marginBottom: '20px',
  a: {
    color: '#dc93e2',
    textDecoration: 'none'
  },
  img: {
    width: '1.5rem',
    height: '1.5rem',
    cursor: 'pointer',
    ml: '1rem'
  },
  variants: {
    status: {
      show: {
        display: 'flex'
      },
      hide: {
        display: 'none'
      }
    }
  }
});

export const InnerInfoCard = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '4px 8px',
  gap: '10px',
  margin: '0 auto',
  background: 'rgba(170, 101, 229, 0.1)',
  border: '1px solid #3C3C3C',
  borderRadius: '4px',
  img: {
    width: '20px',
    height: '20px',
    cursor: 'pointer',
    ml: '1rem'
  },
  variants: {
    status: {
      show: {
        display: 'flex'
      },
      hide: {
        display: 'none'
      }
    }
  }
});

export const MainCard = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  padding: '20px',
  gap: '20px',
  width: '100%',
  background: '#151617',
  border: '1px solid #3C3C3C',
  borderRadius: '16px',
  variants: {
    status: {
      show: {
        display: 'flex'
      },
      hide: {
        display: 'none'
      }
    }
  }
});