import React, { useEffect } from 'react';
import { observer, useLocalStore } from 'mobx-react-lite';
import { HomePage } from '.';
import { Provider } from '@iotexproject/ethers-multicall';
import { useStore } from '../../store/index';
import { useWeb3React } from '@web3-react/core';
import { injectedConnector, walletconnectConnector } from '../../lib/web3-react-bsc';
import { EthConnectWallet } from '../../components/EthConnectWallet';
import { eventBus } from '../../lib/event';
import { metamaskUtils } from '../../utils/metamaskUtils';
import { Web3Provider } from '@ethersproject/providers';
import { ETHMainnetConfig } from '../../config/ETHMainConfig';
import { Link, useHistory } from 'react-router-dom';
import { ethers } from 'ethers';
import axios from 'axios';
import { getKeypairLog, getCommitmentAndNullifierLog, generateTxData, getUserSignatureLog, getSignatures } from '../../store/lib/GetLog';
import BigNumber from 'bignumber.js';
import ModalView from '../../components/ModalView';
import Loading from '../../components/Loading';
import { hooks } from '../../lib/hooks';

export const Mainnet = observer(() => {
  const { god, base, lang } = useStore();
  const { chainId, account, activate, active, library, deactivate } = useWeb3React<Web3Provider>();
  const history = useHistory();

  const store = useLocalStore(() => ({
    logout() {
      deactivate();
      god.eth.connector.latestProvider.clear();
      god.clearAccountInfo();

      god.currentNetwork.account = null;
    },
    async generatePrivateKey(address) {
      const msgParams = [
        {
          type: 'string',
          name: 'Message',
          value: 'Sign this message to generate your ZkMix Privacy Key. This key lets the application decrypt your balance in ZkMix.\n\nIMPORTANT: Signing this message doesn\'t give ZkMix access to your funds.',
        }
      ];
      const from = address;
      const sign = await window.ethereum.request({
        method: 'eth_signTypedData',
        params: [msgParams, from],
      });
      const hash = ethers.utils.keccak256(sign);
      return hash;
    }
  }));
  useEffect(() => {
    const doNecessary = async () => {
      god.currentNetworkName = 'eth';

      god.currentNetwork.chainId = ETHMainnetConfig.chainId;

      if(god.currentChain.poolList.length > 0)
        god.currentPoolIndex = god.currentChain.poolList[0].id;
      
      if (chainId) {
        if (god.currentNetwork.allowChains.includes(chainId)) {
          if(chainId != ETHMainnetConfig.chainId) {
            god.currentPoolIndex = null;
            deactivate();
            god.eth.connector.latestProvider.clear();
            god.clearAccountInfo();
            if(god.currentNetwork.account) {
              while(chainId) {
                await new Promise(r => setTimeout(r, 500));
              }
            }
            god.currentNetwork.account = null;
            history.push(god.currentNetwork.chains[chainId].path);
            return;
          }
        }
      }

      let depositAmount = new BigNumber(10).pow(15);

      let txData, myPubkey;

      const initializeAccount = () => {
        god.currentNetwork.account = account;
        god.currentNetwork.web3 = library;
        //@ts-ignore
        god.eth.ethers = library ? library : god.eth.defaultEthers;
        god.eth.signer = library ? library.getSigner() : null;

        //@ts-ignore
        god.eth.multiCall = new Provider(god.eth.ethers);
        //@ts-ignore
        if (!god.eth.multiCall._multicallAddress) {
          //@ts-ignore
          god.eth.multiCall._multicallAddress = god.Multicall.address;
        }

        if (account) {
          god.setShowConnecter(false);
          god.loadBalance();
          god.loadPublichData();
        }
        if(account) {
          // god.deposit({L1HelperContract: ETHMainnetConfig.L1Helper, HorizonPoolAddress: ETHMainnetConfig.HorizonPool.address, amount: depositAmount, data: txData});
          // god.register({L1HelperContract: ETHMainnetConfig.L1Helper, address: account, publicKey: myPubkey});
        }
      }

      if(account) {
        if( god.currentNetwork.account && god.currentNetwork.account != account ) {
          if(god.myAddress != account && god.loginType == "metamask") { // Metamask login
            const isOkay = await hooks.waitModal({title: lang.t('notification'), msg: lang.t('request.switch')});
            if(!isOkay) {
              if(!god.isRegistered) {
                store.logout();
              }
              else {
                initializeAccount();
                base.startRefetchForce();
              }
              return;
            }
          }
          if(god.loginType == "key") {
            initializeAccount();
            base.startRefetchForce();
            return;
          }
        }
        try {
            god.connectingStatus = 'signing';
            if((!god.myAddress || god.loginType == "metamask") && god.myAddress != account ) {
              let privateKey = await store.generatePrivateKey(account);
              // console.log('privateKey', privateKey);
              god.clearAccountInfo();
              god.loginType = "metamask";
              god.myAddress = account;
              god.myPrivteKey = privateKey;
              god.setLoadingText(lang.t('horizon.connect'));
              god.setLoadingView(true);
              await god.checkRegistration(account);
              god.currentNetwork.account = account;
              if(!god.isRegistered) {
                base.downloadShieldedKey(account, privateKey);
                for(var i = 0; i < god.currentChain.poolList.length; i ++) {
                  god.currentChain.poolList[i].shieldedBalance.setValue(new BigNumber(0));
                  god.currentChain.poolList[i].utxoList = null;
                }
              }
              else {
                god.setLoadingText(lang.t('Loading.text9'));

                for(var i = 0; i < god.currentChain.poolList.length; i ++) {
                  // console.log('god.currentChain.poolList[i].id', god.currentChain.poolList[i].id);
                  await god.checkCommitmentAndNullifer(god.currentChain.poolList[i]);
                  // console.log('finished...');
                }
              }
              // console.log('clearclear');
              god.setLoadingView(false);
            }
            god.connectingStatus = 'idle';

            // let sumBalance = new BigNumber(0);
            // const transferAmount = new BigNumber('150000000000000');
            // const withdrawAmount = new BigNumber('1000000000000');
            // const recipient = '0xe0D5af7FEd599707933380657a0F75B6aD47C2a5';
            // myUtxoArr = myUtxoArr.sort((a, b) => new BigNumber(a.amount.hex).comparedTo(new BigNumber(b.amount.hex))).map((e) => {return {amount: new BigNumber(e.amount.hex), commitment: e._commitment.hex}});
            // console.log(myUtxoArr);
            // let inputCommitments = [];
            // myUtxoArr.map((utxo) => {
            //   sumBalance = sumBalance.plus(utxo.amount);
            // });

            // const feeAmount = transferAmount.div(1000); // 0.1 %
            // const totalAmount = transferAmount.plus(feeAmount);
            // console.log('sumBalance.toString()', sumBalance.toString());

            // sumBalance = new BigNumber(0);
            // let idx = 0;
            // while(sumBalance.comparedTo(totalAmount) < 0) {
            //   sumBalance = sumBalance.plus(myUtxoArr[idx].amount);
            //   inputCommitments.push(myUtxoArr[idx].commitment);
            //   idx ++;
            // }
            // response = await axios.post(ETHMainnetConfig.relayer + '/v1/getAccountStatus', {address: recipient}, {});
            // res = response.data;
            // const recipientPubKey = res.publicKey;

            // const restAmount = sumBalance.minus(totalAmount);

            // let outputData = [];

            // outputData.push({amount: restAmount, pubKey: myPubkey});

            // outputData.push({amount: transferAmount, pubKey: recipientPubKey});

            // await axios.post(ETHMainnetConfig.relayer + '/v1/submitTransferTX', {input: inputCommitments, output: outputData, key: privateKey, fee: feeAmount.toString(), recipient: recipient}, {});
            
            // response = await axios.post(ETHMainnetConfig.relayer + '/v1/getProofData', { amount: depositAmount.toString(), key: privateKey });
            // res = response.data;
            // const {args, extData} = res;

            // console.log('args', args);
            // console.log('extData', extData);

            // txData = await generateTxData(ETHMainnetConfig.HorizonPool, ETHMainnetConfig.gnosisRpcUrl, ETHMainnetConfig.BridgeToken, args, extData, depositAmount);

            // const feeAmount = withdrawAmount.div(1000); // 0.1 %
            // const L1feeAmount = new BigNumber('1000000000');
            // const totalAmount = withdrawAmount.plus(feeAmount).plus(L1feeAmount);
            // console.log('sumBalance.toString()', sumBalance.toString());

            // sumBalance = new BigNumber(0);
            // let idx = 0;
            // while(sumBalance.comparedTo(totalAmount) < 0) {
            //   sumBalance = sumBalance.plus(myUtxoArr[idx].amount);
            //   inputCommitments.push(myUtxoArr[idx].commitment);
            //   idx ++;
            // }
            // const restAmount = sumBalance.minus(totalAmount);

            // let outputData = [];

            // outputData.push({amount: restAmount, pubKey: myPubkey});

            // await axios.post(ETHMainnetConfig.relayer + '/v1/submitWithdrawTX', {input: inputCommitments, output: outputData, key: privateKey, fee: feeAmount.toString(), recipient: recipient, l1fee: L1feeAmount.toString()}, {});

            // const encodedData = await getUserSignatureLog(ETHMainnetConfig.HomeAMB, ETHMainnetConfig.gnosisRpcUrl, 23713605);
            // const signatures = await getSignatures(ETHMainnetConfig.AMBBridgeHelper, ETHMainnetConfig.gnosisRpcUrl, encodedData);
            
            // await axios.post(ETHMainnetConfig.relayer + '/v1/submitUnwrapperTx', {msg: encodedData, signatures: signatures}, {});

            // console.log('signatures', signatures);
        } catch (err) {
          if(!god.currentNetwork.account) {
            store.logout();
          }
          if(god.loginType == "metamask") {
            if(!god.isRegistered) {
              store.logout();
            }
            initializeAccount();
            base.startRefetchForce();
          }
          god.connectingStatus = 'idle';
          return;
        }
      }
      
      initializeAccount()
    }
    doNecessary();
  }, [god, library, account, chainId, active]);

  useEffect(() => {
    if (god.currentNetwork.account) {
      god.loadBalance();
      god.loadPublichData();
    }
  }, [base.refetchTimer]);

  useEffect(() => {
    if (activate && god.eth.connector.latestProvider.value) {
      if (god.eth.connector.latestProvider.value == 'inject') {
        // activate(injectedConnector);
      }
      if (god.eth.connector.latestProvider.value == 'walletconnect') {
        // activate(walletconnectConnector);
      }
    }
  }, [activate, god.eth.connector.latestProvider.value]);

  useEffect(() => {
    const doNecessary = () => {
      const chain = god.eth.chains[ETHMainnetConfig.chainId];
      metamaskUtils.setupNetwork({
        chainId: chain.chainId,
        blockExplorerUrls: [chain.explorerURL],
        chainName: chain.name,
        nativeCurrency: {
          decimals: chain.Coin.decimals || 18,
          name: chain.Coin.symbol,
          symbol: chain.Coin.symbol
        },
        rpcUrls: [chain.baseRpcUrl]
      });
      // console.log('mychain', chain);
      eventBus.addListener('wallet.logout', store.logout);
      return () => eventBus.removeListener('wallet.logout', store.logout);
    };
    doNecessary();
  }, []);

  return (
    <div>
      <EthConnectWallet />
      <HomePage />
    </div>
  );
});
