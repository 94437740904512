import { makeAutoObservable, toJS } from 'mobx';
import { TokenState } from './TokenState';
import { NetworkState } from './NetworkState';
import { LoadingState } from './LoadingState';
import { _ } from '../../lib/lodash';
import { BigNumberState, MulticallParams } from '../type';
import { ChainState } from './ChainState';
import { rootStore } from '../index';
import BigNumber from 'bignumber.js';
import { DepositCYCAmountState } from './DepositCYCAmountState';
import VerifierABI from '../../../public/contracts/Verifier.json';
import { ContractState } from './ContractState';

export enum PoolStateStatus {
  init = 'init',
  onDeposit = 'onDeposit'
}

export class PoolState {
  constructor(args: Partial<PoolState>) {
    Object.assign(this, args);
    makeAutoObservable(this, { network: false, chain: false });
  }
  // data
  network: NetworkState;
  chain: ChainState;
  id: any;
  symbolLogo: string;
  tokeName: string;
  set: string;
  XRCToken?: TokenState;
  relayer: string;
  HorizonPool: ContractState;
  BridgeToken: TokenState;
  symbol: string;
  balance = new BigNumberState({ loading: true });
  poolTVL: BigNumber;
  shieldedBalance = new BigNumberState({ value: new BigNumber(0) });
  utxoList: any = [];
  maxDepositAmount: BigNumber;
  minDepositAmount: BigNumber;

  status: PoolStateStatus = PoolStateStatus.init;
  isShow = false;

  order: Number;

  get icon() {
    return this.symbol.toLowerCase();
  }
  
  get tvl() {
    let res;
    if(!this.XRCToken) {
      res = this.poolTVL.multipliedBy(rootStore.base.coinPrice[this.symbol]);
    }
    else {
      res = this.poolTVL.multipliedBy(rootStore.base.m87PriceUSD.format);
    }
    return res;
  }

}
