import React, { Fragment } from 'react';
import { observer } from 'mobx-react-lite';
import { Wrapper, FlexBox, Text, Icon, Section } from '../../../modules/globalStyle';
import { css } from '../../../modules/index';
import { Headers } from '../../../components/Headers/index';
import { useStore } from '../../../store';

export const HomeTop = observer(() => {
  const { lang, base, god } = useStore();
  return (
    <article className={styles.homeTop.className}>
      <Wrapper>
        <Headers />
      </Wrapper>
    </article>
  );
});

const styles = {
  homeTop: css({
    padding: '1.5rem 0 0 0'
  }),
  miniLine: css({
    width: '100px',
    height: 1,
    backgroundColor: '$primary',
    marginTop: '1.5rem'
  })
};
