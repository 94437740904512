import React, { useEffect, useState } from 'react';
import { useStore } from '../../store';
import { observer, useLocalStore } from 'mobx-react-lite';
import { Wrapper, Text, FlexBox, Icon, Section, Button } from '../../modules/globalStyle';
import { styled, keyframes } from '../../modules/index';
import axios from 'axios';

const langGroups = {
  en: { name: 'EN', src: '/images/EN.png', text: 'en' },
  ru: { name: 'RU', src: '/images/RU.png', text: 'ru' },
  zh_CN: { name: 'CN', src: '/images/CN.png', text: 'zh_CN' }
};


export const CycFooter = observer(() => {
  const { lang, god, base } = useStore();

  const store = useLocalStore(() => ({
    trendingList: []
  }));

  const getCryptoData = async () => {
    try {
        const response = await axios.get(god.currentChain.relayer + '/getTrendingList');
  
        // console.log(response.data);

        store.trendingList = response.data;
  
        
    } catch (error) {
        console.error('Error fetching data:', error.response ? error.response.data : error.message);
    }
  };

  useEffect(() => {
    getCryptoData();
  }, []  )

  return (
    <FooterBox>
      <div style={{ display: 'flex', flexDirection: 'row', width: 'fit-content', animation: 'scroll 50s linear infinite' }}>
          {/* <LinksLanguage />
          <FlexBox justify="end" direction="column" items="end">
            <FlexBox css={{ mb: '0.5rem', cursor: 'pointer' }}>
              <a href={'https://etherscan.io/address/' + god.CYCToken.address} target="_blank">
                <Text size="mini" color="white" cursor="pointer">
                  {lang.t('cyc.token.contract')}: {god.CYCToken.address}
                </Text>
              </a>
              <Section css={{ width: 1, height: 12, backgroundColor: '#AA65E5', mx: '0.8rem' }} />
              <Icon src="/images/home/metamask_logo.png" alt="" onClick={(e) => god.addCYCToMetamask()} />
            </FlexBox>
            <FlexBox>
              <FlexBox>
                <Text size="mini" color="white">
                  {lang.t('token.price')}：
                </Text>
                <Text size="mini" color="primary" cursor="pointer" css={{ textDecoration: 'underline' }}>
                  $ {base.m87PriceUSD.getFormat({decimals: 18, fixed: 9})}
                </Text>
              </FlexBox>
              <Section css={{ width: 1, height: 12, backgroundColor: '#AA65E5', mx: '0.8rem' }} />
              <a href={god.currentNetwork.chains[1].swapURL} target="_blank">
                <Icon src={`/images/home/${god.currentNetwork.chains[1].swapName.toLowerCase()}.png`} alt="" />
              </a>
            </FlexBox>
          </FlexBox> */}
          {
            store.trendingList.map((item) => (
              <div style={{display: 'flex', flexDirection: 'row', gap: '0.2rem', marginRight: '3rem'}}>
                <Text responsive="lg" weight="bold" family="Montserrat" color="white">
                  {item.name}
                </Text>
                <Icon src={item.isBullish ? '/images/home/arrow-up.svg' : '/images/home/arrow-down.svg'} alt="" css={{ width: '24px', height: '24px', marginRight: '10px' }}></Icon>
                <Text responsive="lg" weight="medium" family="Montserrat" color="white">
                  {item.price}
                </Text>
                <Text css={{marginLeft: 10}} responsive="lg" weight="medium" family="Montserrat" color="white">
                  {item.percentage}
                </Text>
              </div>
          ))}
      </div>
    </FooterBox>
  );
});
const FooterBox = styled('article', {
  overflow: 'hidden',
  background: 'linear-gradient(90deg, #150125 50%, #250C35 100%)',
  borderTop: '1px solid #723D9E',
  borderBottom: '1px solid #723D9E',
  padding: '1rem 0',
  '@md': {
    padding: '1.2rem 0 1.2rem 0'
  }
});
