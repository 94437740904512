import React, { useEffect, useMemo } from 'react';
import Button from './Button';
import { Popover } from 'antd';
import { css } from '../modules/index';
import { useStore } from '../store';
import { observer, useLocalStore, useObserver } from 'mobx-react-lite';
import BigNumber from 'bignumber.js';
import { toast } from 'react-toastify';
import { debounce } from 'lodash';
import { useWeb3React } from '@web3-react/core';
import { BigNumberState } from '../store/type';
import { Icon, Text, FlexBox, Wrapper, BorderBox, DropBox, Section, Input } from '../modules/globalStyle';
import { SingleSymbol } from './SingleSymbol';
import LoadingButton from '@mui/lab/LoadingButton';
import { injectedConnector } from '../lib/web3-react-bsc';
import { GasPriceOracle } from 'gas-price-oracle';
import { toWei, toBN, BN } from 'web3-utils';
import { BigNumberInputState } from '../store/lib/BignumberInputState';
import bigInt from 'big-integer';
import axios from 'axios';
import { hooks } from '../lib/hooks';
import { isAddress } from 'web3-utils'
import { styled } from '../modules/index';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { display } from '@mui/system';

export function toHex(number, length = 32) {
  const str = number instanceof Buffer ? number.toString('hex') : bigInt(number).toString(16);
  return '0x' + str.padStart(length * 2, '0');
}

function gasPricesETH(value = 80) {
  const tenPercent = (Number(value) * 5) / 100;
  const max = Math.max(tenPercent, 3);
  const bumped = Math.floor(Number(value) + max);
  return toHex(toWei(bumped.toString(), 'gwei'));
}

const theme = createTheme({
  palette: {
    MessierOrange: {
      main: '#834EB0',
      contrastText: '#fff',
    },
  },
});

let clipboardy = null;

export const TransferView = observer(props => {
  const { lang, god: pageStore, base: baseStore } = useStore();
  const { activate, error, deactivate } = useWeb3React();
  
  const store = useLocalStore(() => ({
    tokens: null,
    // indexOfToken: 0,
    // indexOfAmountSet: 0,
    isShowDetail: false,
    indexOfPool: null,
    currentAmount: new BigNumberState({ loading: true }),
    currentShieldedAmount: new BigNumberState({ value: new BigNumber(0), fixed: 5 }),
    currentCYCDenomination: new BigNumberState({ loading: true }),
    currentCoinDenomination: new BigNumberState({ loading: true }),
    poolSize: -1,
    anonymityFee: new BigNumberState({ loading: true }),
    ApproveStatus: "disabled",
    TokenApproveStatus: "disabled",
    DepositStatus: "disabled",
    WithdrawStatus: "disabled",
    isConnecting: false,
    gasPrice: '0',
    depositAmountInput: new BigNumberInputState({}),
    recipientAddress: null,
    recipientPublicKey: null,
    
    setDepositAmount(val) {
      if(store.currentShieldedAmount.loading)
        return;
      const subArr = val.split(".");
      if(subArr[1]) {
        if(subArr[1].length > store.depositAmountInput.decimals)
          return;
      }
      store.depositAmountInput.setFormat(val);
    },

    setMaxLimitAmount() {
      if(store.currentShieldedAmount.loading)
        return;
      store.depositAmountInput.setValue(this.maxLimitAmount);
    },

    getAnonymityFee() {
      if (!store.indexOfPool) {
        return;
      }
      this.anonymityFee.setValue(pageStore.CurrentSet.anonymityPoolFee.value);
    },
    
    async checkRegisteredAccount(recipient) {
      if(!isAddress(recipient) || recipient == pageStore.myAddress) {
        store.recipientAddress = '';
        return;
      }
      store.recipientAddress = recipient;
      pageStore.setLoadingText(lang.t('request.registered.account'));
      pageStore.setLoadingView(true);
      let response = await axios.post(pageStore.currentChain.relayer + '/v1/getAccountStatus', {address: recipient}, {});
      let res = response.data;
      const { blockNumber, isExist, publicKey } = res;
      pageStore.setLoadingView(false);
      if(isExist) {
        store.recipientPublicKey = publicKey;
        store.recipientAddress = recipient;
      }
      else {
        store.recipientPublicKey = null;
        await hooks.waitModal({title: lang.t('notification'), msg: lang.t('not.registered')});
        store.recipientAddress = '';
      }
    },

    // get currentCYCDenominationLabel() {
    //   return '...';
    // },

    get xrcApproved() {
      if( pageStore.CurrentSet.XRCToken ) {
        return (
          new BigNumber(pageStore.CurrentSet.XRCToken.allownaceForRouter.value).comparedTo(store.currentAmount.value) >= 0 &&
          store.currentAmount.value.comparedTo('0') > 0
        );
      }
      return false;
    },

    async fetchGasPrice(netId) {
      try {
        const options = {
        chainId: netId,
        defaultRpc: pageStore.currentChain.rpcUrl,
        timeout: 10000,
        defaultFallbackGasPrices: {
          instant: 28,
          fast: 22,
          standard: 17,
          low: 11,
        },
        };
        // Bump fees for Ethereum network
        if (netId == 1 || netId == 56 || netId == 137) {
          const oracle = new GasPriceOracle(options);
          const fallbackGasPrices = {
            instant: 70,
            fast: 31,
            standard: 20,
            low: 7,
          };
          const gas = await oracle.gasPrices(fallbackGasPrices);
          return gasPricesETH(gas.instant);
        }
      } catch (err) {
        return '0x0';
      }
    },
    
    init(chainID) {
      // console.log('EthereumConfig.tokensOnEthereum[chainID] =', EthereumConfig.tokensOnEthereum[chainID]);

      store.tokens = pageStore.currentChain.pools;
      // console.log('store.tokens =', store.tokens);
      // console.log('cyc denomination', store.currentCYCDenomination.format);
      // console.log(store.tokens.length);
      if( store.tokens ) {
        // console.log('ininin');
        this.indexOfPool = props.indexOfPool;
      }
      // console.log('this.indexOfPool =', this.indexOfPool);
      this.loadAmount();
    },

    loadAmount() {
      if (pageStore.web3) {
        store.getCurrentAmount();
        // store.getPoolSize();
        // store.getAnonymityFee();
        // store.getCurrentCYCDenomination();
        // store.getCurrentCoinDenomination();
      }
      store.getCurrentShieldedAmount();
      if (pageStore.account) {
        // pageStore.getBalanceOfXRC();
        // pageStore.getBNBtoBuyCYC();
        // pageStore.getBalanceOfCoin();
      }
    },

    // onSelectAmount(id) {
    //   pageStore.setCurrentSelectedAmount(id);
    // },

    // onSelectToken(event) {
    //   const idx = event.target.value;
    //   store.indexOfToken = idx;
    //   props.onSelectToken(idx);
    // },

    onSelectPool() {
      pageStore.setCurrentSelectedAmount(store.indexOfPool);
      if(pageStore.CurrentSet.XRCToken)
        store.currentAmount.setDecimals(pageStore.CurrentSet.XRCToken.decimals);
      this.loadAmount();
    },

    async onConnect() {
      if(!pageStore.myAddress) {

        pageStore.connectingStatus = "connecting";
        pageStore.setShowConnecter(true);
        if(!pageStore.isConnect && pageStore.connectingStatus == 'connecting') {
          pageStore.connectingStatus = 'idle';
        }
        if(pageStore.isConnect) {
          baseStore.startRefetchForce();
        }
      }
      else {
        pageStore.connectingStatus = "connecting";
        await pageStore.register();
        if(!pageStore.isRegistered && pageStore.connectingStatus == 'connecting') {
          pageStore.connectingStatus = 'idle';
        }
        if(pageStore.isRegistered) {
          baseStore.startRefetchForce();
        }
      }

    },

    logout() {
      deactivate();
      pageStore.latestEthProvider.clear();
    },

    // onSelectPool(event) {
    //   const tempInt = event.target.value;
    //   store.indexOfPool = tempInt;
    //   props.onSelectAmount(tempInt);
    // },

    async onApproveXRC() {
      store.TokenApproveStatus = "loading";
      pageStore.isOnProgress = true;
      try {
        await props.onApproveXRC(pageStore.CurrentSet.XRCToken, store.currentAmount.value.toString());
        store.TokenApproveStatus = "disabled";
        pageStore.isOnProgress = false;
      } catch (error) {
        store.TokenApproveStatus = "notApproved";
        pageStore.isOnProgress = false;
      }
    },

    async deposit() {
      store.DepositStatus = "loading";
      pageStore.isOnProgress = true;
      try {
        await props.onDeposit(store.totalSendAmount, store.recipientPublicKey, store.relayerFeeAmount);
        store.DepositStatus = "notActive";
        store.depositAmountInput.setValue(new BigNumber(0));
        pageStore.isOnProgress = false;
        store.recipientAddress = '';
      } catch (error) {
        store.DepositStatus = "active";
        pageStore.isOnProgress = false;
      }
    },

    async getCurrentAmount() {
      if (!store.indexOfPool || pageStore.CurrentSet.balance.loading) {
        return;
      }
      const value = pageStore.CurrentSet.balance.value;
      store.currentAmount.setValue(value);
      store.currentAmount.setLoading(false);
    },

    getCurrentShieldedAmount() {
      if (!store.indexOfPool) {
        return;
      }
      const value = pageStore.CurrentSet.shieldedBalance.value;
      store.currentShieldedAmount.setValue(value);
    },

    getCurrentCoinDenomination() {
      if (!store.indexOfPool) {
        return;
      }

      const value = pageStore.CurrentSet.coinDenomination.value;
      store.currentCoinDenomination.setValue(value);
      store.currentCoinDenomination.setLoading(false);
    },

    async getCurrentCYCDenomination() {
      if (!store.indexOfPool) {
        return;
      }

      // const set = store.tokens[store.indexOfToken].amountSteps[store.indexOfPool];
      const value = pageStore.CurrentSet.depositCYCAmount.value;
      store.currentCYCDenomination.setValue(value);
      store.currentCYCDenomination.setLoading(false);
    },

    async getPoolSize() {
      if (!store.indexOfPool) {
        return;
      }

      store.poolSize = -1;
      if (store.tokens) {
        store.poolSize = pageStore.CurrentSet.numOfShare;
      }
    },

    onClickCopyButton(event) {
      if (clipboardy) {
        clipboardy.focus();
        clipboardy.select();
        const result = document.execCommand('copy');
        if (result === 'unsuccessful') {
          clipboardy.blur();
        } else {
          clipboardy.blur();
          toast.dark(lang.t('address.copied'), {
            position: 'bottom-center',
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: false,
            progress: undefined
          });
        }
      }
    },

    onChangeBuyCYCCheckBox(event) {
      if (!store.indexOfPool) {
        return;
      }
      
      pageStore.CurrentSet.allowBuyCYC = event.target.checked && !event.target.disabled;
    },

    get allowDeposit() {
      if (store.depositAmountInput.value.comparedTo(0) > 0 && store.depositAmountInput.value.comparedTo(store.maxLimitAmount) <= 0 && store.recipientPublicKey) {
        return true;
      }
      return false;
    },
    
    get maxLimitAmount() {
      const totalBalance = store.currentShieldedAmount.value;
      // ######### temporaly fee = 0 #############
      const expense = new BigNumber(0);
      // const expense = totalBalance.dividedBy(1000).dp(0, BigNumber.ROUND_FLOOR);
      let maxValue = totalBalance.minus(expense);

      return maxValue;
    },

    get bridgeFeeAmount() {
      return new BigNumber(0);
    },
    
    get bridgeFeeFormat() {
      return new BigNumberState({value: this.bridgeFeeAmount}).format;
    },

    get relayerFeeAmount() {
      // ####### temporarly relayer_fee = 0 #######
      return new BigNumber(0);

        const totalBalance = store.currentShieldedAmount.value;
        // console.log('totalBalance', totalBalance.toString());
        if(store.depositAmountInput.value.eq(store.maxLimitAmount)) {
          return totalBalance.dividedBy(1000).dp(0, BigNumber.ROUND_FLOOR);
        }
        return store.depositAmountInput.value.dividedBy(1000).dp(0, BigNumber.ROUND_FLOOR);
    },

    get relayerFeeFormat() {
      return new BigNumberState({value: this.relayerFeeAmount}).format;
    },

    get totalSendAmount() {
      return store.depositAmountInput.value.plus(store.relayerFeeAmount);
    },

    get totalSendFormat() {
      return new BigNumberState({value: this.totalSendAmount}).format;
    },
    
  }));

  const ActionButton = useObserver(() => {
    return (
      <div>
        
      </div>
    );
  });

  const AnonymityPool = useObserver(() => {
    if(store.isShowDetail)
    {
      if (!pageStore.isRegistered) {
        return (
          <PoolCard>
            <FlexBox justify="center" items="center" style={{width: '100%'}}>
              <ThemeProvider theme={theme}>
                <LoadingButton
                  color="MessierOrange"
                  loadingPosition='start'
                  loading={store.isConnecting}
                  variant="contained"
                  onClick={store.onConnect}
                  sx={{
                    height: '40px',
                    fontWeight: 'bold',
                    maxWidth: '200px',
                    width: '80%',
                    borderRadius: '8px'
                  }}
                  fullWidth
                  >
                  {lang.t('connect')}
                </LoadingButton>
              </ThemeProvider>
            </FlexBox>
          </PoolCard>
        );
      }
      return (
        <div>
        <PoolCard>
          <FlexBox justify="between" style={{width: '100%'}} responsive="rc">
            <Section css={{ width: '100%', mb: '1.5rem', '@md': { width: '46%', mb: 0 } }}>
              <div
                style={{
                  marginTop: '0.5rem'
                }}
              >
                {/* {store.currentAmount.value.comparedTo(0) <= 0 && (
                  <BorderBox css={{ padding: '0.6rem 0'}}>
                    <div style={{
                          alignItems: 'center',
                          display: 'flex',
                          fontSize: '15px',
                          color: '#959191',
                          paddingBottom: '12px'
                    }}>
                      {lang.t('balance')}:&nbsp;
                      {pageStore.balanceOfCoin.getFormat({ decimals: pageStore.currentChain.Coin.decimals }) + ' ' + pageStore.currentChain.Coin.symbol}  &nbsp;
                      {store.currentCoinDenomination.value.comparedTo(pageStore.balanceOfCoin.value) > 0 && (
                        <>
                          <Popover
                            placement="top"
                            trigger="click"
                            content={
                              <>
                                <div style={{ textAlign: 'center', fontWeight: 'bold', maxWidth: '12rem' }}>
                                  {lang.t('amount.invalid')}
                                </div>
                                <div
                                  style={{
                                    textAlign: 'center',
                                    fontWeight: 'bold',
                                    maxWidth: '12rem',
                                    pointerEvents: 'auto'
                                  }}
                                ></div>
                              </>
                            }
                            backgroundColor="#45bcb8"
                          >
                            <img src="/images/alert.png" style={{ cursor: 'pointer' }} alt="" />
                          </Popover>
                        </>
                      )}
                    </div>

                    <div
                      className={styles.denomenation.className}
                      style={{
                        marginBottom: '0.5rem'
                      }}
                    >
                      <div
                        style={{
                          alignItems: 'center',
                          display: 'flex',
                          fontSize: '18px',
                          color: 'white'
                        }}
                      >
                        {pageStore.currentChain.Coin.symbol} &nbsp;{lang.t('Denomination')}:&nbsp;
                        {store.currentCoinDenomination.getFormat({
                        decimals: pageStore.currentChain.Coin.decimals,
                        fixed: 8
                      })}
                      </div>
                    </div>
                  </BorderBox>
                )} */}

                  <BorderBox css={{ padding: '0.2rem 0'}}>
                    <div
                      className={styles.denomenation.className}
                      style={{
                        marginBottom: '0.5rem'
                      }}
                    >
                      <Text responsive="md" weight="medium" family="Montserrat" color="textPrimary" >
                        {lang.t('shielded')}&nbsp;{lang.t('balance')}&nbsp;:&nbsp;{store.currentShieldedAmount.format}&nbsp;{store.indexOfPool}
                      </Text>
                    </div>
                  </BorderBox>

                  <div style={{
                          alignItems: 'center',
                          display: 'flex',
                          paddingTop: '35px',
                          display: 'flex',
                          width: '100%',
                          flexDirection: 'column'
                    }}>
                        <div style={{display: 'flex', justifyContent: "space-between", width: '100%'}}>
                          <Text responsive="sm" weight="medium" family="Montserrat" color="textPrimary" css={{marginBottom: '0.3rem'}} >
                            {lang.t('relayer.fee')}
                          </Text>
                          <Text responsive="sm" weight="medium" family="Montserrat" color="textPrimary" css={{marginBottom: '0.3rem'}} >
                            {store.relayerFeeFormat}&nbsp;{ store.indexOfPool }
                          </Text>
                        </div>
                        {/* <div style={{display: 'flex', justifyContent: "space-between", width: '100%'}}>
                          <Text responsive="sm" weight="medium" family="Montserrat" color="textPrimary" css={{marginBottom: '0.3rem'}} >
                            {lang.t('bridge.fee')}
                          </Text>
                          <Text responsive="sm" weight="medium" family="Montserrat" color="textPrimary" css={{marginBottom: '0.3rem'}} >
                            {store.bridgeFeeFormat}&nbsp;{ store.indexOfPool }
                          </Text>
                        </div> */}
                        <div style={{display: 'flex', justifyContent: "space-between", width: '100%'}}>
                          <Text responsive="sm" weight="medium" family="Montserrat" color="textPrimary" css={{marginBottom: '0.3rem'}} >
                            {lang.t('total.transfer')}
                          </Text>
                          <Text responsive="sm" weight="medium" family="Montserrat" color="textPrimary" css={{marginBottom: '0.3rem'}} >
                            {store.totalSendFormat}&nbsp;{ store.indexOfPool }
                          </Text>
                        </div>
                    </div>

                {/* {store.currentCYCDenomination.value.comparedTo(0) > 0 && pageStore.account && (
                  <BorderBox css={{ padding: '0.6rem 0'}}>
                    <div style={{
                          alignItems: 'center',
                          display: 'flex',
                          fontSize: '15px',
                          color: '#959191',
                          paddingBottom: '12px'
                    }}>
                      {lang.t('balance')}:&nbsp;
                      {pageStore.balanceOfCYC.format}
                      &nbsp;M87&nbsp;
                      {store.currentCYCDenomination.value.comparedTo(pageStore.balanceOfCYC.value) > 0 ? (
                        <>
                          <Popover
                            placement="top"
                            trigger="click"
                            content={
                              <>
                                <div style={{ textAlign: 'center', fontWeight: 'bold', maxWidth: '12rem' }}>
                                  {lang.t('cyc.tips')}
                                </div>
                                <div
                                  style={{
                                    textAlign: 'center',
                                    fontWeight: 'bold',
                                    maxWidth: '12rem',
                                    pointerEvents: 'auto'
                                  }}
                                >
                                  <a
                                    style={{
                                      color: 'black',
                                      textDecoration: 'underline'
                                    }}
                                    href={pageStore.currentChain.swapURL}
                                  >
                                    {lang.t('click.buy')}
                                  </a>
                                </div>
                              </>
                            }
                            backgroundColor="#45bcb8"
                          >
                            <img src="/images/alert.png" style={{ cursor: 'pointer' }} alt="" />
                          </Popover>
                        </>
                      ) : null}
                    </div>

                    <div className={styles.denomenation.className}>
                      <div
                        style={{
                          alignItems: 'center',
                          display: 'flex',
                          fontSize: '18px',
                          color: 'white'
                        }}
                      >
                        {lang.t('cyc.denomenation')}:&nbsp;{store.currentCYCDenomination.format}
                        <Popover
                            placement="top"
                            trigger="click"
                            content={
                              <>
                                <div style={{ textAlign: 'center', fontWeight: 'bold', maxWidth: '12rem' }}>
                                  {lang.t('cyc.reward')}
                                </div>
                              </>
                            }
                            backgroundColor="#45bcb8"
                          >
                            <img src="/images/reward.png" style={{ cursor: 'pointer', paddingLeft: 5, marginTop: -2 }} alt="" />
                        </Popover>
                      </div>

                      
                    </div>

                    {pageStore.balanceOfCYC.value.comparedTo(store.currentCYCDenomination.value) < 0 && pageStore.account && (
                      <div
                        className="flex item-center"
                        style={{
                          marginTop: '0.5rem'
                        }}
                      >
                        <input
                          disabled={
                            pageStore.balanceOfCoin.value.comparedTo(
                              store.currentCoinDenomination.value.plus(pageStore.BNBtoBuyCYC.value)
                            ) < 0
                          }
                          id="check"
                          type="checkbox"
                          style={{ verticalAlign: 'middle' }}
                          onChange={store.onChangeBuyCYCCheckBox}
                        />
                        <label htmlFor="check" style={{ marginLeft: 10, verticalAlign: 'middle', color: 'white' }}>
                          {lang.t('use.coin.to.buy.cyc', {
                            amount: pageStore.BNBtoBuyCYC.format,
                            symbol: pageStore.currentChain.Coin.symbol
                          })}
                        </label>
                      </div>
                    )}
                  </BorderBox>
                )} */}

                {/* {store.currentAmount.value.comparedTo(0) > 0 && (
                  <BorderBox css={{ padding: '0.6rem 0'}}>
                    <div style={{
                          alignItems: 'center',
                          display: 'flex',
                          fontSize: '15px',
                          color: '#959191',
                          paddingBottom: '12px'
                    }}>
                      {lang.t('balance')}:&nbsp;
                      {pageStore.balanceOfCoin.getFormat({ decimals: pageStore.currentChain.Coin.decimals })} ETH
                      {store.currentCoinDenomination.value.comparedTo(pageStore.balanceOfCoin.value) > 0 ? (
                        <>
                          <Popover
                            placement="top"
                            trigger="click"
                            content={
                              <>
                                <div style={{ textAlign: 'center', fontWeight: 'bold', maxWidth: '12rem' }}>
                                  {lang.t('amount.invalid')}
                                </div>
                                <div
                                  style={{
                                    textAlign: 'center',
                                    fontWeight: 'bold',
                                    maxWidth: '12rem',
                                    pointerEvents: 'auto'
                                  }}
                                ></div>
                              </>
                            }
                            backgroundColor="#45bcb8"
                          >
                            <img src="/images/alert.png" style={{ cursor: 'pointer' }} alt="" />
                          </Popover>
                        </>
                      ) : null}
                    </div>

                    <div
                      className={styles.denomenation.className}
                      style={{
                        marginBottom: '0.5rem'
                      }}
                    >
                      <div
                        style={{
                          alignItems: 'center',
                          display: 'flex',
                          fontSize: '18px',
                          color: 'white'
                        }}
                      >
                        ETH&nbsp;{lang.t('Denomination')}:&nbsp;
                        {store.currentCoinDenomination.getFormat({ decimals: pageStore.currentChain.Coin.decimals })}
                      </div>
                    </div>
                  </BorderBox>
                )} */}
              </div>
            </Section>
            
            <Section css={{ width: '100%', '@md': { width: '46%' } }}>
              {/* <Button
                avaliable={
                  pageStore.isConnect &&
                  !store.cycApproved &&
                  new BigNumber(pageStore.balanceOfCYC.value).comparedTo('0') &&
                  store.currentCYCDenomination.value.comparedTo(0) > 0
                }
                label={lang.t('approve') + ' M87'}
                disabledLabel={lang.t('approved')}
                disable={store.cycApproved}
                onClick={store.onApproveCYC}

                LoadingButton
              /> */}
              <div style={{ flexDirection: 'column', justifyContent: 'space-between', display: 'flex' }}>
                <Section css={{ position: 'relative', mb: '1.5rem' }}>
                  <Input
                    type="number"
                    min={0}
                    step={0.1}
                    css={{ pr: '50px' }}
                    value={store.depositAmountInput.format}
                    onChange={(e) => store.setDepositAmount(e.target.value)}
                  />
                  <Text
                    size="small"
                    color="primary"
                    cursor="pointer"
                    onClick={store.setMaxLimitAmount}
                    css={{ position: 'absolute', lineHeight: '40px', top: 0, right: 11 }}
                  >
                    {lang.t('Max')}
                  </Text>
                </Section>
                <Section css={{ position: 'relative', mb: '1.5rem' }}>
                  <Input
                    value={store.recipientAddress}
                    placeholder={lang.t('copy.recipient.address')}
                    onChange={(e) => {store.checkRegisteredAccount(e.target.value)}}
                  />
                </Section>
                <ThemeProvider theme={theme}>
                  {/* {
                    pageStore.CurrentSet.XRCToken && store.currentAmount.value.comparedTo(0) > 0 ?
                      <LoadingButton
                      color="MessierOrange"
                      loadingPosition='start'
                      loading={store.TokenApproveStatus == "loading"}
                      disabled={store.TokenApproveStatus != "notApproved"}
                      variant="contained"
                      onClick={store.onApproveXRC}
                      sx={{
                        height: '40px',
                        fontWeight: 'bold',
                        color: store.TokenApproveStatus != "notApproved" ? '#acb3bb !important' : '',
                        background: store.TokenApproveStatus != "notApproved" ? '#b9b9b952 !important' : ''
                      }}
                      fullWidth
                      >
                        { store.TokenApproveStatus == "approved" ? lang.t('approved') : lang.t('approve.token', { token: pageStore.CurrentSet.XRCToken.symbol })}
                      </LoadingButton> : (
                          <LoadingButton
                          color="MessierOrange"
                          loadingPosition='start'
                          loading={store.ApproveStatus == "loading"}
                          disabled={store.ApproveStatus != "notApproved"}
                          variant="contained"
                          onClick={store.onApproveCYC}
                          sx={{
                            height: '40px',
                            fontWeight: 'bold',
                            color: store.ApproveStatus != "notApproved" ? '#acb3bb !important' : '',
                            background: store.ApproveStatus != "notApproved" ? '#b9b9b952 !important' : ''
                          }}
                          fullWidth
                          >
                            { store.ApproveStatus == "approved" ? lang.t('approved') : lang.t('approve')}
                          </LoadingButton>
                      )
                  } */}
                  
                  <LoadingButton
                    color="MessierOrange"
                    loadingPosition='start'
                    loading={store.DepositStatus == "loading"}
                    disabled={store.DepositStatus != "active"}
                    variant="contained"
                    sx={{
                      borderRadius: '8px',
                      height: '40px',
                      fontWeight: 'bold',
                      color: store.DepositStatus != "active" ? '#acb3bb !important' : '',
                      background: store.DepositStatus != "active" ? '#b9b9b952 !important' : ''
                    }}
                    onClick={store.deposit}
                    fullWidth
                  >
                    {lang.t('Transfer')}
                  </LoadingButton>
                </ThemeProvider>
              </div>
             {/* <div className={styles.buttonPanel.className}>{ActionButton}</div> */}
            </Section>
          </FlexBox>
        </PoolCard>
      </div>
      );
    }
  });

  useEffect(() => {
    // console.log('props.chainID', props.chainID);
    store.init(props.chainID);
  }, []);

  useEffect(() => {
    if(pageStore.requestedPoolIndex && pageStore.requestedPoolIndex == store.indexOfPool) {
      store.onSelectPool();
      pageStore.requestedPoolIndex = null;
    }
  }, [pageStore.requestedPoolIndex]);

  useEffect(() => {
    // console.log('pageStore.connectingStatus', pageStore.connectingStatus);
    if(pageStore.connectingStatus == 'idle')
      store.isConnecting = false;
    else
      store.isConnecting = true;
  }, [pageStore.connectingStatus]);

  useEffect(() => {
    store.isShowDetail = store.indexOfPool == pageStore.currentPoolIndex;
  }, [pageStore.currentPoolIndex]);

  useEffect(() => {
    // console.log('DepositStatus', store.DepositStatus);
    if(store.DepositStatus == "loading")
      return;
    if( store.allowDeposit )
      store.DepositStatus = "active";
    else
      store.DepositStatus = 'notActive';
  }, [ store.allowDeposit, baseStore.refetchTimer ]);

  useEffect(() => {
    if( !pageStore.CurrentSet.XRCToken )
      return;
    // console.log("Approve consider");
    if(store.TokenApproveStatus == "loading")
      return;
    // console.log(store.ApproveStatus);
    if( store.xrcApproved ) {
      store.TokenApproveStatus = "approved";
      // console.log('1');
    }
    else {
      // console.log('2'); 
      const isActive = pageStore.isConnect &&
        new BigNumber(pageStore.balanceOfXRC.value).comparedTo('0') &&
        store.currentAmount.value.comparedTo(0) > 0;
      if( isActive )
        store.TokenApproveStatus = "notApproved";
      else
        store.TokenApproveStatus = "disabled";
    }
  }, [pageStore.isConnect, store.xrcApproved, pageStore.balanceOfXRC, store.currentAmount ]);

  useEffect(() => {
    // console.log('props.chainID =', props.chainID);

    store.init(props.chainID);
  }, [props.chainID, pageStore.web3, pageStore.account]);

  useEffect(() => {
    store.loadAmount();
    const doNecessary = async () => {
      store.gasPrice = await store.fetchGasPrice(pageStore.currentChain.chainId);
    }
    doNecessary();
  }, [baseStore.refetchTimer, pageStore.CurrentSet.balance.value]);

  return (
    <>
    { 
      <Section css={{  overflow: 'hidden'}}>
        {/* <BorderBox css={{ padding: '0.6rem 0', cursor: 'pointer' }}
        onClick={
          () => {
              store.onSelectPool(); 
          }
        }>
          <FlexBox justify="between">
            <FlexBox css={{ width: 'max-content', cursor: 'pointer', '@md': { width: '32%' } }}>
              {
                <SingleSymbol imgs={['/images/home/' + (store.indexOfPool ? store.indexOfPool.toLowerCase() : '') + '.png']} />
              }
              <Text size="small" color="primary" css={{ ml: '0.8rem' }}>
                <span>
                  {store.tokens &&
                    store.tokens[store.indexOfPool].set
                  }
                </span>
              </Text>
            </FlexBox>
            <Text size="small" color="white" css={{ flex: 1, textAlign: 'right', cursor: 'pointer' }}>
              { (pageStore.currentChain.chainId == 1) && (store.indexOfPool == 1 || store.indexOfPool == 10) && store.tokens[store.indexOfPool].Apy.format + ' % / ' + store.tokens[store.indexOfPool].dailyApy.getFormat({fixed: 3}) + ' %' }
            </Text>
            <FlexBox justify="end" css={{ width: 'max-content', cursor: 'pointer', '@md': { width: '40%' } }}>
              <Text size="small" color="white" css={{ display: 'none', '@md': { display: 'block' } }}>
                ${store.tokens[store.indexOfPool].tvl.format}
              </Text>
              <Icon
                css={{ width: '1rem', ml: '1rem', '@md': { width: '1.375rem', ml: '1.5rem' } }}
                src={`/images/home/${store.isShowDetail ? 'dropup' : 'dropdown'}.png`}
              ></Icon>
            </FlexBox>
          </FlexBox>
        </BorderBox> */}
        <div style={{width: '100%'}}>
          <div style={{position: 'relative'}}>
            <BlurEffect />
          </div>
        </div>
        {AnonymityPool}
        <div style={{width: '100%'}}>
          <div style={{position: 'relative'}}>
            <BlurEffect />
          </div>
        </div>
      </Section>
    }
    </>
  );
});

const styles = {
  depositView: css({
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    height: '530',
    '@md': {
      minHeight: '390px'
    }
  }),
  token: css({
    marginTop: '1.2rem',
    marginLeft: '7vw',
    '@xs': {
      marginTop: '25px',
      marginLeft: '40px'
    },
    '.select': {
      border: '1px solid #45BCB8',
      color: 'white',
      background: 'transparent',
      height: '40px',
      width: '95%',
      appearance: 'none',
      fontSize: '18px',
      fontFamily: "'IBM Plex Mono', monospace",
      paddingLeft: '0.5rem'
    }
  }),
  amount: css({
    width: '95%',
    overflow: 'hidden',
    marginTop: '2rem',
    marginLeft: '7vw',
    marginBottom: '2rem',
    '@xs': {
      width: '94%',
      overflow: 'auto',
      marginLeft: '40px',
      paddingRight: '40px'
    },
    '.stepperTrack': {
      width: '100%',
      borderWidth: '2px',
      borderStyle: 'solid',
      borderColor: '#45bcb8',
      marginTop: '20px'
    }
  }),
  buttonPanel: css({
    height: '40px',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#ffffff12',
    color: '#44ee95',
    fontSize: '16px',
    fontWeight: 'bold',
    cursor: 'pointer',
    textTransform: 'uppercase',
    border: 'solid 1px',
    borderColor: '#44ee95',
    '&:hover': {
      backgroundColor: '#00ff0312'
    },
    marginBottom: '1rem',
    marginTop: '2.5rem',
  }),
  bottomTips: css({
    marginLeft: '10%',
    marginRight: '4vw',
    fontSize: '12px',
    padding: '0 0 0.3rem 3rem',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    '@md': {
      marginLeft: '64px',
      marginRight: '28px',
      fontSize: '12px',
      paddingLeft: '40px',
      paddingBottom: '0.35rem',
      whiteSpace: 'nowrap'
    }
  }),
  poolInfos: css({
      marginTop: '0.7rem'
  }),
  poolAudio: css({
    paddingRight: '5%',
    ul: {
      listStyleType: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
      padding: 0,
      margin: 0,
      li: {
        width: '100%',
        padding: '4px 0',
        border: '1px solid #45BCB8',
        textAlign: 'left',
        marginBottom: '8px',
        paddingLeft: '4px',
        fontSize: '0.875rem',
        cursor: 'pointer',
        '@md': {
          width: '48%'
        },
        '&:hover': {
          background: 'rgba(69, 188, 184, 0.35)'
        }
      },
      '.active': {
        fontWeight: 'bold',
        color: '#38DCD5',
        borderColor: '#45BCB8',
        background: 'rgba(69, 188, 184, 0.35)'
      }
    }
  }),
  logo: css({
    width: '24px',
    height: '24px'
  }),
  denomenation: css({
    width: '95%',
    rowGap: '0.5rem',
    flexBetweenCenter: 'column',
    '.input': {
      marginBottom: '8px'
    },
    '.button': {
      width: '100%'
    },
    '.buttonDisable': {
      width: '100%'
    },
    '@xs': {
      columnGap: '1rem',
      flexBetweenCenter: 'row',
      '.input': {
        marginBottom: 0
      },
      '.button': {
        width: 'max-content'
      },
      '.buttonDisable': {
        width: 'max-content'
      }
    },
    '@md': {
      '.input': {
        marginBottom: 0
      }
    }
  }),
  approveBtn: css({
    padding: '0 10px',
    width: '100%',
    height: '34px',
    outline: 'none',
    border: '1px solid #45BCB8',
    color: 'white',
    fontWeight: 'bold',
    fontSize: '0.875rem',
    backgroundColor: '#45BCB8',
    '@xs': {
      height: '40px',
      maxWidth: '152px'
    },
    '@md': {
      maxWidth: '90px',
      fontSize: '12px'
    },
    xl: {
      maxWidth: '152px',
      fontSize: '1rem'
    },
    '&:disabled': {
      backgroundColor: 'black'
    }
  }),
  loginOut: css({
    flexCenterCenter: 'row',
    paddingLeft: '8%',
    marginBottom: '2rem',
    '@md': {
      marginBottom: '3rem'
    },
    span: {
      textDecoration: 'underline',
      fontSize: '1',
      cursor: 'pointer',
      fontWeight: 'bold'
    }
  })
};

export default TransferView;

export const PoolCard = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '20px',
  width: '100%',
  background: '#1E1F21',
  border: '1px solid #3C3C3C',
  borderRadius: '12px',
  minHeight: '200px',
  img: {
    width: '1.5rem',
    height: '1.5rem',
    cursor: 'pointer',
    ml: '1rem'
  },
  variants: {
    status: {
      show: {
        display: 'flex'
      },
      hide: {
        display: 'none'
      }
    }
  }
});

export const BlurEffect = styled('div', {
  position: 'absolute',
  width: '60%',
  height: '25px',
  left: '20%', // Center horizontally
  top: '-12px ', // Center vertically
  background: '#5729B8',
  opacity: '0.7',
  filter: 'blur(20px)',
  transform: 'matrix(1, 0, 0, -1, 0, 0)'
});