import { makeAutoObservable } from 'mobx';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';
import zh from 'javascript-time-ago/locale/zh';
import ru from 'javascript-time-ago/locale/ru';
import { rootStore } from './index';
import { analyticsETHClient } from '../lib/analytics-bsc-gql';
import { eventBus } from '../lib/event';
import { marketUtils } from '../utils/market';
import { isMobile } from "react-device-detect";
import BigNumber from 'bignumber.js';
import { BigNumberState } from '../store/type';
import axios from 'axios';
import { saveAs } from 'file-saver';

TimeAgo.addDefaultLocale(en);
TimeAgo.addLocale(ru);
TimeAgo.addLocale(zh);

export class BaseStore {
  constructor() {
    makeAutoObservable(this);
    // console.log('BaseStore');
    this.startRefetch();
  }

  timer: any;

  downloadShieldedKey = (address, priavetKey) => {
      
    const filecontent = new Blob([priavetKey], {
      type: "text/plain"
    });
    const filename = 'ZkMix Shielded Key - ' + address.substring(0, 5) + '.txt';
    saveAs(filecontent, filename)
  };

  startRefetch() {
    if (this.timer) clearInterval(this.timer);
    this.timer = setInterval(() => {
      eventBus.emit('amount.refetch');
      this.refetchTimer += 1;
    }, 15000);
  }

  startRefetchForce() {
    eventBus.emit('amount.refetch');
    this.refetchTimer += 1;
    this.startRefetch();
  }

  tempIndex = Number(window.localStorage.getItem('tempIndex'));
  timeAgos = {
    en: new TimeAgo('en-US'),
    ru: new TimeAgo('ru-RU'),
    zh_CN: new TimeAgo('zh-CN')
  };
  get timeAgo() {
    const timeAgo = this.timeAgos[rootStore.lang.lang];
    return timeAgo || this.timeAgos.en;
  }
  ip = '';
  geo = '';
  apy = 0;
  refetchTimer = 0;
  cycPrice = 0;
  ethPrice = 0;
  coinPrice = [];
  m87PriceETH = new BigNumber('0');
  m87PriceUSD = new BigNumberState({ value: new BigNumber(0), viewerFixed: 9 });
  tvl = 0;

  loading = {
    visible: false,
    msg: '',
    showConfirm: true,
    showCancel: false,
    showLoading: false,
    confirmText: ''
  };

  pendingPool = {
    visible: false,
  };

  modal = {
    visible: false,
    title: '',
    msg: ''
  };
  setModal(args: Partial<BaseStore['modal']>) {
    Object.assign(this.modal, { visible: true }, args);
  }
  closeModal() {
    this.modal = {
      visible: false,
      title: '',
      msg: ''
    };
  }

  setPendingPool() {
    Object.assign(this.pendingPool, { visible: true });
  }
  closePendingPool() {
    this.pendingPool = {
      visible: false,
    };
  }

  closeLoading() {
    this.loading = {
      visible: false,
      msg: '',
      showConfirm: true,
      showCancel: false,
      showLoading: false,
      confirmText: ''
    };
  }

  setLoading(args: Partial<BaseStore['loading']>) {
    Object.assign(this.loading, { visible: true, showLoading: false, showConfirm: true }, args);
  }

  async getCycPrice() {
    let response = await axios.get('https://min-api.cryptocompare.com/data/price?fsym=ETH&tsyms=BTC,USD,EUR');
    const price = response.data.USD;
    response = await axios.get('https://min-api.cryptocompare.com/data/price?fsym=BNB&tsyms=BTC,USD,EUR');
    const priceBNB = response.data.USD;
    response = await axios.get('https://min-api.cryptocompare.com/data/price?fsym=MATIC&tsyms=BTC,USD,EUR');
    const priceMatic = response.data.USD;
    this.coinPrice['ETH'] = price;
    this.coinPrice['BNB'] = priceBNB;
    this.coinPrice['MATIC'] = priceMatic;
    // this.coinPrice['ETH'] = '1445'
    // this.coinPrice['BNB'] = '280';
    // this.coinPrice['MATIC'] = '0.9';
    this.ethPrice = this.coinPrice['ETH'];
    this.m87PriceETH = await rootStore.god.getM87PriceETH();

    this.m87PriceUSD.setValue(this.m87PriceETH.multipliedBy(this.ethPrice));

    await rootStore.god.getTotalTVL();
  }
  init() {
    this.getCycPrice();
  }
}