import { ETHNetworkState } from '../store/lib/NetworkState';
// import { BSCMainnetConfig } from './BSCMainnetConfig';
// import { BSCTestnetConfig } from './BSCTestnetConfig';
// import { ETHRopstenConfig } from './ETHRopstenConfig';
import { ETHMainnetConfig } from './ETHMainConfig';
// import { ETHRinkebyConfig } from './ETHRinkebyConfig';
// import { PolygonMainnetConfig } from './PolygonMainnetConfig';

// latest poolId: 15

export const EthereumConfigV2 = new ETHNetworkState({
  defaultChainId: ETHMainnetConfig.chainId,
  allowChains: [ETHMainnetConfig.chainId/*, BSCMainnetConfig.chainId, PolygonMainnetConfig.chainId*/],
  chains: {
    // [BSCMainnetConfig.chainId]: BSCMainnetConfig,
    [ETHMainnetConfig.chainId]: ETHMainnetConfig,
  }
});