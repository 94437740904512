import React from 'react';
import { observer, useLocalStore } from 'mobx-react-lite';
import { FlexBox, Text, BorderBox, Section, Icon } from '../../modules/globalStyle';
import { useStore } from '../../store/index';
import { styled } from '../../modules';


import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';

const SwitchItem = styled('div', {
  width: 24,
  height: 24,
  lineHeight: '24px',
  cursor: 'pointer',
  '.name': {
    textAlign: 'center'
  },
  variants: {
    bg: {
      active: {
        backgroundColor: '$primaryOpacity'
      },
      normal: {
        backgroundColor: 'transparent'
      }
    }
  }
});

export const LpMiningHeader = observer(() => {
  const { lang, setting, god } = useStore();
  const store = useLocalStore(() => ({
    anchorEl: null,

    toggleSwtich() {
      setting.approveMax.save(!setting.approveMax.value);
    },
    get open() {
      return Boolean(this.anchorEl);
    },
    setAnchorEl(element) {
      this.anchorEl = element;
    },

    handlePopoverOpen (event) {
      this.curSelectedId = event.currentTarget.getAttribute('id');
      this.setAnchorEl(event.currentTarget);
    },

    handlePopoverClose () {
      this.setAnchorEl(null);
    },
  }));
  return (
    <Section css={{marginBottom: '0.5rem'}}>
      <FlexBox justify="start" items="center">
      <Text responsive="md" weight="medium" family="Montserrat" color="textPrimary" >
        {lang.t('anonymity_pools')}
      </Text>
      <Icon onMouseEnter={store.handlePopoverOpen} onMouseLeave={store.handlePopoverClose} src="/images/home/info-circle.svg" css={{ marginLeft: '0.5rem', width: '16px' }} alt="" />
        <Popover
          id="mouse-over-popover"
          color='primary'
          sx={{
            pointerEvents: 'none',
            width: '100%',
            minWidth: '200px',
          }}
          open={store.open}
          anchorEl={store.anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          onClose={store.handlePopoverClose}
          disableRestoreFocus
        >
          <Typography color='white' style={{backgroundColor: '#121212', fontSize: 15}} sx={{ p: 1 }}>
           <p>
            <Text responsive="sm" weight="medium" family="Montserrat" color="white" >
              Ethereum - MIN(0.05 ETH), MAX(100 ETH)
            </Text>
            </p>
           <p>
            <Text responsive="sm" weight="medium" family="Montserrat" color="white" >
              ZkLock - MIN(1,000 ZKLK), MAX(100,000,000 ZKLK)
            </Text>
           </p>
          </Typography>
        </Popover>
      </FlexBox>
    </Section>
  );
});
