import React, { useState, useEffect } from 'react';
import { useStore } from '../../store';
import { observer, useLocalStore } from 'mobx-react-lite';
import { Link, useHistory } from 'react-router-dom';
import { Wrapper, Text, FlexBox, Icon, BorderBox, Button, Section } from '../../modules/globalStyle';
import { styled } from '../../modules/index';
import { metamaskUtils } from '../../utils/metamaskUtils';
import { hooks } from '../../lib/hooks';

function getIsMetaMask(): boolean {
  return window.ethereum?.isMetaMask ?? false
}

export const ChainNav = observer(() => {
  const { lang, base, god } = useStore();
  const history = useHistory();
  const store = useLocalStore(() => ({
    current: history.location.pathname,
    chains: [
      {
        name: 'Ethereum',
        logo: '/images/home/eth_n.svg',
        path: '/eth',
        active_logo: '/images/home/eth_s.svg',
        chainID: 1,
      },
      // {
      //   name: 'BSC',  
      //   logo: '/images/home/bsc_n.svg',
      //   path: '/bsc',
      //   active_logo: '/images/home/bsc_s.svg',
      //   chainID: 56,
      // },
      // {
      //   name: 'Polygon',
      //   logo: '/images/home/matic_n.svg',
      //   path: '/polygon',
      //   active_logo: '/images/home/matic_s.svg',
      //   chainID: 137
      // },
    ],
    get currentObj() {
      if(store.current == '/compliance')
        return store.chains[0];
      return store.chains.find((i) => i.path == store.current);
    },
  }));

  useEffect(() => {
    store.current = history.location.pathname;
  }, [history.location.pathname]);

  return (
    <>
      <Section css={{ display: 'none', '@md': { display: 'block', position: 'relative', mr: '1rem' } }}>
        <DropItem color="normal">
          <FlexBox justify="between" css={{width: '100%'}}>
            <FlexBox css={{flex: 1, justifyContent: 'center'}}>
                <Icon src={store.currentObj.active_logo} alt="" css={{ width: '25px', height: '25px', marginRight: '5px' }}></Icon>
                <Text color="textPrimary" size="small" weight="normal" family="Montserrat">{store.currentObj.name}</Text>
            </FlexBox>
            <Icon src="/images/home/btn_dropdown.png" alt="" css={{ width: 24, height: 24 }}></Icon>
          </FlexBox>
        </DropItem>
        <DropdownBox>
          <Section className="dropBox" css={{ backgroundColor: '$bg9' }}>
            {store.chains &&
              store.chains.map((item, index) => (
                <DropItem
                  key={item.path}
                  onClick={() => {
                    // god.currentPoolIndex = null;
                    // store.current = item.path;
                    // if( getIsMetaMask() ) {
                    //   const chain = god.eth.chains[item.chainID];
                    //   metamaskUtils.setupNetwork({
                    //     chainId: chain.chainId,
                    //     blockExplorerUrls: [chain.explorerURL],
                    //     chainName: chain.name,
                    //     nativeCurrency: {
                    //       decimals: chain.Coin.decimals || 18,
                    //       name: chain.Coin.symbol,
                    //       symbol: chain.Coin.symbol
                    //     },
                    //     rpcUrls: [chain.baseRpcUrl]
                    //   });
                    // }
                    // else {
                    //   hooks.waitModal({title: lang.t('notification'), msg: lang.t('toNet.wallet')});
                    // }
                    history.push(item.path);
                  }}
                  css={{
                    backgroundColor: store.current === item.path ? '$bg9' : 'transparent',
                    '&:hover': {
                      img: {
                        content: `url(${item.active_logo}) !important`
                      },
                      ".name": {
                        color: '$textPrimary'
                      }
                    }
                  }}
                >
                  <Icon
                    src={store.current === item.path ? item.active_logo : item.logo}
                    css={{
                      width: '25px',
                      height: item.name == 'Polygon' ? '20px' : '25px',
                      marginRight: '5px',
                      '&:hover': {
                        content: `url(${item.active_logo})`
                      }
                    }}
                  />
                  <Text className="name" color="light" size="md" weight="semibold" cursor="pointer" family="Montserrat">{item.name}</Text>
                </DropItem>
              ))}
          </Section>
        </DropdownBox>
      </Section>

      <FlexBox css={{ display: 'flex', '@md': { display: 'none' } }}>
        {store.chains &&
          store.chains.map((item, index) => (
            <DropItem
              key={item.path}
              onClick={() => {
                god.currentPoolIndex = null;
                store.current = item.path;
                    // if( getIsMetaMask() ) {
                    //   const chain = god.eth.chains[item.chainID];
                    //   metamaskUtils.setupNetwork({
                    //     chainId: chain.chainId,
                    //     blockExplorerUrls: [chain.explorerURL],
                    //     chainName: chain.name,
                    //     nativeCurrency: {
                    //       decimals: chain.Coin.decimals || 18,
                    //       name: chain.Coin.symbol,
                    //       symbol: chain.Coin.symbol
                    //     },
                    //     rpcUrls: [chain.baseRpcUrl]
                    //   });
                    // }
                    // else {
                    //   hooks.waitModal({title: lang.t('notification'), msg: lang.t('toNet.wallet')});
                    // }
                history.push(item.path);
              }}
              css={{
                padding: 0,
                width: '100%',
                textAlign: 'center',
                backgroundColor: store.current === item.path ? '$bg9' : '$bg3',
                '&:hover': {
                  img: {
                    content: `url(${item.active_logo}) !important`
                  },
                }
              }}
            >
              <FlexBox justify="center" css={{width: '100%', marginRight: 10}}>
                <Icon
                  src={store.current === item.path ? item.active_logo : item.logo}
                  css={{
                    width: item.name == 'Polygon' ? '25px' : '35px',
                    height: item.name == 'Polygon' ? '20px' : '25px',
                    marginRight: '5px',
                    '&:hover': {
                      content: `url(${item.active_logo})`
                    }
                  }}
                />
                <Text color={store.current === item.path  ? "white": "light"} size="md" weight="semibold" cursor="pointer">{item.name}</Text>
              </FlexBox>
            </DropItem>
          ))}
      </FlexBox>
    </>
  );
});

const DropdownBox = styled('div', {
  position: 'absolute',
  width: '169px',
  height: '52px',
  top: 0,
  left: 0,
  '.dropBox': {
    position: 'absolute',
    borderRadius: '8px',
    top: 50,
    left: 0,
    display: 'none',
    cursor: 'pointer'
  },
  '&:hover': {
    height: 90,
    '.dropBox': {
      display: 'block'
    }
  }
});

const DropItem = styled('div', {
  width: '172px',
  height: '50px',
  backgroundColor: 'rgba(170, 101, 229, 0.1)',
  border: '1px solid #3C3C3C',
  borderRadius: '8px',
  padding: '0 9px 0 11px',
  boxSizing: 'border-box',
  display: 'flex',
  alignItems: 'center',
  variants: {
    color: {
      normal: {
        '&:hover': {
          backgroundColor: '$bg3'
        }
      }
    }
  }
});
