import { makeAutoObservable } from 'mobx';
import { NetworkState, ETHNetworkState } from './lib/NetworkState';
import { EthereumConfigV2 } from '../config/NetowkConfig';
import BigNumber from 'bignumber.js';
import { ChainState } from './lib/ChainState';
import { PoolState, PoolStateStatus } from './lib/PoolState';
import { rootStore } from '.';
import { TokenState } from './lib/TokenState';
import { metamaskUtils } from '../utils/metamaskUtils';
import Config from '../Config';
import axios from 'axios';
import { publicConfig } from '../config/public';
import { _ } from '../lib/lodash';
import { AeolusContractState } from './lib/ContractState';
import { Int } from '../../generated/gql/schema';
import { hooks } from '../lib/hooks';
import Web3 from 'web3';
import { toBN } from 'web3-utils';
import merkleTree from 'fixed-merkle-tree';
import { getKeypairLog, getCommitmentAndNullifierLog, getPoolBalance } from './lib/GetLog';
import { TransactionState } from './lib/TransactionState';
import { BigNumberState } from '../store/type';

export type Network = 'eth';

export class GodStore {
  isPublicInfoLoaded: boolean = false;
  isPrivateInfoLoaded: boolean = false;
  isRegistered: boolean = false;
  myPublicKey: any = null;
  myPrivteKey: any = null;
  myAddress: any = null;
  loginType: any = null;
  connectingStatus: any = 'idle';
  showLoadingView: boolean = false;
  loadingText: any;
  isQualifiedForFee: boolean = false;
  basicFeeRatio: BigNumber;
  higherFeeRatio: BigNumber;
  zklkTransferFee: BigNumber;
  isOnProgress: boolean = false;
  currentNetworkName: Network = 'eth';
  currentPoolIndex: any = null;
  requestedPoolIndex: any = null;
  currentNote: any;
  eth: ETHNetworkState = EthereumConfigV2;
  ABIs = new Map();
  withdrawAnnoucement: any =  "";
  currentPrivateKey: string = null;
  constructor() {
    this.eth.god = this;
    makeAutoObservable(this);
  }

  get chains() {
    return [this.eth];
  }

  get isConnect() {
    return !!this.currentNetwork.account;
  }

  get isETH() {
    return this.currentNetworkName == 'eth';
  }
  get currentNetwork(): NetworkState {
    return this.getNetwork(this.currentNetworkName);
  }

  get currentChain(): ChainState {
    return this.currentNetwork.currentChain;
  }

  get Coin() {
    return this.currentChain.Coin;
  }

  get CYCToken() {
      return this.currentChain.CYCToken;
  }

  get Aeolus() {
    return this.currentChain.Aeolus;
  }
  get AeolusV2() {
    return this.currentChain.AeolusV2;
  }

  get LPToken() {
    return this.currentChain.LPToken;
  }

  get Multicall() {
    return this.currentChain.MultiCall;
  }
  
  get latestEthProvider() {
    return this.eth.connector.latestProvider;
  }

  get CurrentSet() {
    return this.currentChain.pools[this.currentPoolIndex];
  }

  get account() {
    return this.eth.account;
  }

  get web3() {
    return this.eth.web3;
  }

  get balanceOfXRC() {
    if(this.CurrentSet.XRCToken)
      return this.CurrentSet.XRCToken.balance;
  }

  get balanceOfCoin() {
    return this.currentChain.Coin.balance;
  }

  setLoadingView(isShown) {
    if(isShown) {
      document.body.style.overflow = 'hidden';
    }
    else {
      document.body.style.overflow = 'auto';
    }
    this.showLoadingView = isShown;
  }

  setLoadingText(text) {
    this.loadingText = text;
  }

  getNetwork(network: string) {
      return this.eth;
  }

  findPool({ poolId }: { poolId: string }) {
    let pool: PoolState;
    this.chains.forEach((network) => {
      Object.values(network.chains).forEach((chain) => {
        if (chain.pools[poolId]) {
          pool = chain.pools[poolId];
        }
      });
    });
    return pool;
  }

  getPool({
    network = this.currentNetworkName,
    chainId = this.currentChain.chainId,
    poolId
  }: {
    network?: string;
    chainId?: number | string;
    poolId: number | string;
  }) {
    return this.getNetwork(network).chains[chainId].pools[poolId];
  }

  getChain({ network = this.currentNetworkName, chainId = this.currentChain.chainId }: { network?: string; chainId?: number | string }) {
    return this.getNetwork(network).chains[chainId];
  }

  async getM87PriceETH() {
    let price;
    // console.log('getAmountsIn');
    try { 
      await this.currentNetwork.mainnetMulticallV2([
        await this.currentNetwork.uniswapMulticall({
          method: 'getAmountsIn',
          params: ['1000000000000000000', ['0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2', '0x96884fcAAc082Db4B32601aDA5b177FD6cBFFA88']],
          handler: (v: any) => {
            price = new BigNumber(v[0].toString());
            // console.log('price', price.toString(), v.toString());
          }
        }),
      ]);
    } catch (error) {
      price = new BigNumber(0);
    }
    // console.log('price', price.toString());
    return price;
  }

  async getTotalTVL() {
    let sumOfTvl = new BigNumber(0);
    const tvlState = new BigNumberState({ value: new BigNumber(0), viewerFixed: 3});
    await Promise.all(this.currentNetwork.allowChains.map(async (indexOfChainID) => {
      const chain = this.currentNetwork.chains[indexOfChainID];
      const { poolList } = chain;
      
      for(var i = 0; i < poolList.length; i ++) {
        let res = await getPoolBalance(poolList[i].BridgeToken, this.currentChain.gnosisRpcUrl, poolList[i].HorizonPool.address);
        poolList[i].poolTVL = new BigNumber(res.toString());
        sumOfTvl = sumOfTvl.plus(poolList[i].tvl);     
      }

      tvlState.setValue(sumOfTvl);
      rootStore.base.tvl = tvlState.format;
    }));
  }

  async loadPublichData() {
    const xrcList = this.currentChain.poolList.filter((i) => i.XRCToken);

    await this.currentNetwork.multicallV2([
      ..._.flattenDeep([
        this.currentChain.L1Helper.preMulticall({
          method: 'basicFeeRatio',
          params: [],
          handler: (v: any) => {
            this.basicFeeRatio = new BigNumber(v.toString());
            // pool.balance.setValue(balance);
            // pool.XRCToken.balance.setValue(balance);
          }
        }),
        this.currentChain.L1Helper.preMulticall({
          method: 'higherFeeRatio',
          params: [],
          handler: (v: any) => {
            this.higherFeeRatio = new BigNumber(v.toString());
            // pool.balance.setValue(balance);
            // pool.XRCToken.balance.setValue(balance);
          }
        }),
        this.currentChain.L1Helper.preMulticall({
          method: 'isQualifiedForFee',
          params: [this.currentNetwork.account],
          handler: (v: any) => {
            this.isQualifiedForFee = v;
            // const balance = new BigNumber(v.toString());
            // pool.balance.setValue(balance);
            // pool.XRCToken.balance.setValue(balance);
          }
        }),
        this.currentChain.L1Helper.preMulticall({
          method: 'feeMap',
          params: [this.currentChain.CYCToken.address],
          handler: (v: any) => {
            this.zklkTransferFee = new BigNumber(v.toString());
            // pool.balance.setValue(balance);
            // pool.XRCToken.balance.setValue(balance);
          }
        }),
      ]),
      ..._.flattenDeep(
        xrcList.map((pool) => [
          pool.XRCToken.preMulticall({
            method: 'balanceOf',
            params: [this.currentNetwork.account],
            handler: (v: any) => {
              const balance = new BigNumber(v.toString());
              pool.balance.setValue(balance);
              pool.XRCToken.balance.setValue(balance);
            }
          }),
          pool.XRCToken.preMulticall({
            method: 'allowance',
            params: [this.currentNetwork.account, this.currentChain.L1Helper.address],
            handler: (v: any) => {
              const allowance = new BigNumber(v.toString());
              pool.XRCToken.allownaceForRouter.setValue(allowance);
            }
          })
        ])
      ),
    ]);
  }

  async loadBalance() {
    const { poolList } = this.currentChain;
    poolList.map((pool) => {
      if(!pool.XRCToken) {
        this.currentNetwork.loadBalance(pool.balance);
      }
    });
  }

  async approve({ token, spender, value }: { token: TokenState; spender: string; value: string }) {
    // console.log('token', token);
    // console.log('spender', spender);
    // console.log('value', value);
    token.metas.isApprovingAllowance = true;
    const { approveMax } = rootStore.setting;
    const approveValue = approveMax.value ? publicConfig.maxApprove : value;
    // const approveValue = true ? publicConfig.maxApprove : value;
    try {
      const res = await this.currentNetwork.execContract({
        address: token.address,
        abi: token.abi,
        method: 'approve',
        params: [spender, approveValue]
      });
      const receipt = await res.wait();
      if (receipt.status) {
        token.metas.isApprovingAllowance = false;
        token.allownaceForRouter.setValue(new BigNumber(approveValue).plus(token.allownaceForRouter.value));
      }
    } catch (error) {
      token.metas.isApprovingAllowance = false;
      throw new Error("Error while approving");
    }
  }

  async CliamToken({ aeolus }: { aeolus: AeolusContractState }) {
    // console.log('aeolus', aeolus);
    await this.currentNetwork.execContract({
      address: aeolus.address,
      abi: aeolus.abi,
      method: 'deposit',
      params: [0]
    });
  }

  async deposit({ amount, data }: { amount: any, data: any }) {
    // console.log('this.CurrentSet.address', this.CurrentSet.address);
    // console.log('commitment', commitment);
    // console.log('this.CurrentSet.allowBuyCYC', this.CurrentSet.allowBuyCYC);
    const config = this.currentChain;
    const tokenAddress = this.CurrentSet.XRCToken ? this.CurrentSet.XRCToken.address : '0x0000000000000000000000000000000000000000';
    // console.log('data', data);
    try {
      let res;
      if(this.isRegistered) {
        res = await this.currentNetwork.execContract({
          address: config.L1Helper.address,
          abi: config.L1Helper.abi,
          method: 'wrapAndRelayTokens(address,address,uint256,bytes)',
          params: [this.CurrentSet.HorizonPool.address, tokenAddress, amount.toString(), data],
          options: {
            value: this.CurrentSet.XRCToken ? 0 : amount.toString()
          }
        });
      }
      else {
        res = await this.currentNetwork.execContract({
          address: config.L1Helper.address,
          abi: config.L1Helper.abi,
          method: 'wrapAndRelayTokens(address,address,uint256,bytes,(address,bytes))',
          params: [this.CurrentSet.HorizonPool.address, tokenAddress, amount.toString(), data, { owner: this.myAddress, publicKey: this.myPublicKey }],
          options: {
            value: this.CurrentSet.XRCToken ? 0 : amount.toString()
          }
        });
      }
      // const curTx = new TransactionState({
      //   createTime: new Date().getTime(),
      //   amountToken: this.CurrentSet.XRCToken ? this.CurrentSet.tokenDenomination.format : '0',
      //   amountCYC: '0',
      //   amountCoin: this.CurrentSet.id,
      //   txHash: res.hash,
      //   note: this.currentNote,
      //   commitment: commitment,
      //   status: 2,
      //   chainId: this.currentChain.chainId,
      //   poolId: this.currentPoolIndex,
      //   tokenSymbol: this.CurrentSet.XRCToken ? this.CurrentSet.XRCToken.symbol : null
      // })
      // const txStore = rootStore.transaction;
      // txStore.addTransaction(curTx);
      return res;
      // const receipt = await res.wait();
      // if (receipt.status) {
      //   txStore.transactions[txStore.transactions.length - 1].status = 1;
      //   txStore.save();
      // };
    } catch (error) {
        // console.log(error);
        throw new Error("Error while depositing");
    }
  }
  
  async register() {
    this.setLoadingText(rootStore.lang.t('registering'));
    this.setLoadingView(true);
      
    const L1HelperContract = this.currentChain.L1Helper;
    const address = this.currentNetwork.account;
    const publicKey = this.myPublicKey;
    // console.log('L1HelperContract', L1HelperContract);
    // console.log('address', address);
    // console.log('publicKey', publicKey);
    try {
      const res = await this.currentNetwork.execContract({
        address: L1HelperContract.address,
        abi: L1HelperContract.abi,
        method: 'register',
        params: [{owner: address, publicKey: publicKey}],
      });
      // const curTx = new TransactionState({
      //   createTime: new Date().getTime(),
      //   amountToken: this.CurrentSet.XRCToken ? this.CurrentSet.tokenDenomination.format : '0',
      //   amountCYC: '0',
      //   amountCoin: this.CurrentSet.id,
      //   txHash: res.hash,
      //   note: this.currentNote,
      //   commitment: commitment,
      //   status: 2,
      //   chainId: this.currentChain.chainId,
      //   poolId: this.currentPoolIndex,
      //   tokenSymbol: this.CurrentSet.XRCToken ? this.CurrentSet.XRCToken.symbol : null
      // })
      // const txStore = rootStore.transaction;
      // txStore.addTransaction(curTx);
      
      const receipt = await res.wait();
      await this.checkRegistration(this.currentNetwork.account);
      this.setLoadingView(false);
      // if (receipt.status) {
      //   txStore.transactions[txStore.transactions.length - 1].status = 1;
      //   txStore.save();
      // };
      hooks.waitLoading({ msg: rootStore.lang.t('notification'), confirmText: rootStore.lang.t('signup.success') });
    } catch (error) {
        // console.log('error', error);
        // throw new Error("Error while depositing");
        this.setLoadingView(false);
    }
  }

  async sendTxFee(recipient, rawFee) {
      
    const L1UnwrapperContract = this.currentChain.L1Unwrapper;
      const res = await this.currentNetwork.execContract({
        address: L1UnwrapperContract.address,
        abi: L1UnwrapperContract.abi,
        method: 'sendTxFee',
        params: [recipient],
        options: {
          value: rawFee.toString()
        }
      });
      await res.wait();
  }

  async checkRegistration(address) {
    const config = this.currentChain;
    let response = await axios.post(config.relayer + '/v1/getAccountStatus', {address: address}, {});
    let res = response.data;
    const { blockNumber, isExist, publicKey } = res;
    if(isExist) {
      this.isRegistered = true;
      this.myPublicKey = publicKey;
    }
    else {
      response = await axios.post(config.relayer + '/v1/getPubkey', {key: this.myPrivteKey}, {});
      res = response.data;
      this.myPublicKey = res.publicKey;
    }
    const event = await getKeypairLog(blockNumber, config.L1Helper, config.rpcUrl, config.relayer, this.myPublicKey);
    // console.log(event);
    if(event) {
      this.isRegistered = true;
      this.myPublicKey = event.publicKey;
    }
  }

  async checkPrivateKey(privateKey) {
    const config = this.currentChain;

    let response = await axios.post(config.relayer + '/v1/getPrivateKeyStatus', {key: privateKey}, {});
    let res = response.data;
    const { address, blockNumber, publicKey, isExist, invalid } = res;

    if(invalid) {
      return { invalid: true };
    }
    else {
      this.myPublicKey = publicKey;
      if(isExist) {
        this.isRegistered = true;
        this.myAddress = address;
        this.myPrivteKey = privateKey; 
      }
      const event = await getKeypairLog(blockNumber, config.L1Helper, config.rpcUrl, config.relayer, publicKey);
      if(event) {
        this.isRegistered = true;
        this.myAddress = event.address;
        this.myPrivteKey = privateKey; 
      }
      return { invalid: false }
    }
  }

  async checkCommitmentAndNullifer(pool) {
    const config = this.currentChain;
    let response = await axios.post(pool.relayer + '/v1/getCommitmentAndNullifierStatus');
    let res = response.data;
    const {commitmentblockNumber, nullifierBlockNumber} = res;
    // console.log(pool.id, 'commentlog started...');
    await getCommitmentAndNullifierLog(commitmentblockNumber, nullifierBlockNumber, pool.HorizonPool, config.gnosisRpcUrl, pool.relayer);
    // console.log('commentlog finished...');
    response = await axios.post(pool.relayer + '/v1/getUTXOFromKey', {key: this.myPrivteKey}, {});
    res = response.data;

    const myUtxoArr = res.UTXOArr.sort((a, b) => new BigNumber(a.amount.hex).comparedTo(new BigNumber(b.amount.hex))).map((e) => {return {amount: new BigNumber(e.amount.hex), commitment: e._commitment.hex}});
    let sumBalance = new BigNumber(0);
    myUtxoArr.map((utxo) => {
      // console.log('utxo', utxo);
      // console.log(this.currentPoolIndex);
      // console.log('utxo.amount.String()', utxo.amount.toString());
      sumBalance = sumBalance.plus(utxo.amount);
    });
    pool.shieldedBalance.setValue(sumBalance);
    pool.utxoList = myUtxoArr;
    // console.log('monitor');
    // console.log(pool.id, 'myUtxoArr', myUtxoArr);
    rootStore.base.startRefetchForce();
  }

  clearAccountInfo() {
    this.myAddress = this.myPrivteKey = this.myPublicKey = null;
    this.isRegistered = false;
    this.loginType = null;
  }

  async ApproveLP({ amount, aeolus }: { amount: string; aeolus: AeolusContractState }) {
    const { LpToken } = aeolus;
    LpToken.metas.isApprovingAllowance = true;
    try {
      const res = await this.currentNetwork.execContract({
        address: LpToken.address,
        abi: LpToken.abi,
        method: 'approve',
        params: [aeolus.address, amount]
      });
      const receipt = await res.wait();
      if (receipt.status) {
        LpToken.metas.isApprovingAllowance = false;
        LpToken.allowanceForAeolus.setValue(new BigNumber(amount));
      }
    } catch (error) {
      LpToken.metas.isApprovingAllowance = false;
    }
  }
  async StakeLP({ amount, aeolus }: { amount: string; aeolus: AeolusContractState }) {
    // console.log(aeolus);
    const res = await this.currentNetwork.execContract({
      address: aeolus.address,
      abi: aeolus.abi,
      method: 'deposit',
      params: [amount]
    });
    const receipt = await res.wait();
    if (receipt.status) {
      rootStore.base.startRefetchForce();
    }
  }
  async UnStakeLP({ amount, aeolus }: { amount: string; aeolus: AeolusContractState }) {
    const res = await this.currentNetwork.execContract({
      address: aeolus.address,
      abi: aeolus.abi,
      method: 'withdraw',
      params: [amount]
    });
    const receipt = await res.wait();
    if (receipt.status) {
      rootStore.base.startRefetchForce();
    }
  }

  setShowConnecter(value) {
    this.currentNetwork.connector.showConnector = value;
  }

  setCurrentSelectedAmount(index) {
    // console.log('setCurrentSelectedAmount(index)', index)
    if (!this.isOnProgress) {
      if(this.currentPoolIndex != index) {
        this.currentPoolIndex = index;
        // console.log('this.currentPoolIndex', this.currentPoolIndex)
      }
    }
  }

  async addCYCToMetamask() {
    if(this.currentChain.chainId == 1)
      metamaskUtils.registerToken(this.CYCToken.address, this.CYCToken.symbol, this.CYCToken.decimals, Config.baseURL + '/images/home/zklk_logo.svg');
  }
}
