import React, { useState } from 'react';
import { styled } from '../../modules/index';
import { Icon, Text } from '../../modules/globalStyle';

interface IComponentProps {
  content?: any;
}

export const Notice = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '20px',
  width: '100%',
  background: '#1E1F21',
  border: '1px solid #3C3C3C',
  borderRadius: '12px',
  overflow: 'hidden',
  fontSize: '0.75rem',
  lineHeight: '1.2rem',
  color: '$textPrimary',
  a: {
    color: '#dc93e2',
    textDecoration: 'none'
  },
  img: {
    width: '1.5rem',
    height: '1.5rem',
    cursor: 'pointer',
    ml: '1rem'
  },
  variants: {
    status: {
      show: {
        display: 'flex'
      },
      hide: {
        display: 'none'
      }
    }
  }
});

export const BlurEffect = styled('div', {
  position: 'absolute',
  width: '60%',
  height: '25px',
  left: '20%', // Center horizontally
  top: '-12px ', // Center vertically
  background: '#5729B8',
  opacity: '0.7',
  filter: 'blur(20px)',
  transform: 'matrix(1, 0, 0, -1, 0, 0)'
});

export const NoticeItem = (props: IComponentProps) => {
  const { content } = props;
  const [isShow, setStatus] = useState(true);
  return (
    <div style={{width: '100%', overflow: 'hidden', marginBottom: 20}}>
      <Notice status={isShow ? 'show' : 'hide'}>
        <Text color="white" weight="normal" family="Montserrat" css={{ flex: 1, textAlign: 'center', fontSize: '14' }}>{content}</Text>
        <Icon
          onClick={() => setStatus(false)}
          src="/images/home/close-circle.png"
          css={{
            '&:hover': {
              content: 'url("/images/home/close-circle_s.png")'
            }
          }}
          alt=""
        />
      </Notice>
      <div style={{width: '100%'}}>
        <div style={{position: 'relative'}}>
          <BlurEffect />
        </div>
      </div>
    </div>
  );
};
