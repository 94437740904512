import BigNumber from 'bignumber.js';
import React, { useEffect } from 'react';
import Utility from '../Utility';
import { toast } from 'react-toastify';
import TextButton from './TextButton';
import { useStore } from '../store';
import { observer, useLocalStore } from 'mobx-react-lite';
import ReactTooltip from 'react-tooltip';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Popconfirm } from 'antd';
import axios from 'axios';

let clipboardy = null;

export const TransactionRecord = observer(props => {
  const { lang, base, god: pageStore } = useStore();
  const store = useLocalStore(
    props => ({
      formatAmount(str) {
        return new BigNumber(str)
          .dividedBy(Math.pow(10, 18))
          .toFixed(6)
          .toString();
      },

      onClickRemoveButton(id) {
        // console.log(id);
        props.removeTransactionRecord(id);
      },

      onClickCopyButton(id) {
        if (clipboardy) {
          clipboardy.value = props.data[id].note;
          clipboardy.focus();
          clipboardy.select();
          const result = document.execCommand('copy');
          if (result === 'unsuccessful') {
            console.error(lang.t('check.note.fail'));
          } else {
            toast.dark(lang.t('check.note.copied'), {
              position: 'bottom-center',
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: false,
              pauseOnHover: false,
              draggable: false,
              progress: undefined
            });
          }
        }
      },

      showStatus(idOfStatus) {
        switch (idOfStatus) {
          case 0:
            return lang.t('waiting.for.receipt');

          case 1:
            return lang.t('Deposited');

          case 2:
            return lang.t('Sending');

          case 3:
            return lang.t('Spent');
        }
      },
    }),
    props,
  );
  useEffect(() => {
    let deposits = [];

    const fetchData = async () => {
      try {
        const response = await axios.post(pageStore.CurrentSet.relayer + '/v1/getNumofSubsequents', deposits, {});
        const { result } = response.data;
        // console.log('result', result);
        props.data.map((deposit, index) => {
          deposit.subsequent = result[index];
        });
      } catch(error) {
        // console.log('error');
        throw new Error("Error");
      };
    }
        
    if(props.data && props.data.length > 0) {
      props.data.map((deposit) => {
        deposits.push({id: deposit.amountCoin, commitment: deposit.commitment});
      });
      // console.log('deposits', deposits);
      fetchData()
    }
	}, [props.data])
 
    return (
    <>
      {props.data && props.data.length > 0 && (
        <div style={{display: 'flex', justifyContent: 'center'}}>
          <div className="borderedView" style={{ overflowX: 'auto', width: '90%', height: '100%', maxWidth: '942px', marginTop: '-30px' }}>
            <div className="table">
              <div className="tr box boxheader-tx">
                <div className="td">
                  {lang.t('time.passed')}
                </div>

                {/* {God.isBSC && <div className="td">{lang.t('Pool')}</div>} */}

                <div className="td">
                  {lang.t('amount')}
                </div>
              
                <div className="td">
                  {lang.t('subsequent.deposits')}
                </div>
               

                <div className="td">{lang.t('tx.hash')}</div>
                <div className="td" style={{ display: 'flex', alignItems: 'center' }}>
                  <InfoCircleOutlined data-tip="status-tips" data-for="status-tips" style={{ marginRight: '2px' }} />
                  <ReactTooltip
                    place="top"
                    type="warning"
                    id="status-tips"
                    effect="solid"
                    backgroundColor="#45bcb8"
                    delayHide={500}
                  >
                    {lang.t('status_warning')}
                  </ReactTooltip>
                  {lang.t('status')}
                </div>
              </div>

              {props.data.map((item, index) => (
                <div className="tr box box-tx" key={item.txHash} >
                  <div className="td" style={{padding: 0, display: 'flex', width: '135px'}}>
                    <div className='headerBar'></div>
                    <div style={{padding: '20px 0px 20px 10px'}}>{item.createTime && base.timeAgo.format(new Date(item.createTime))}</div>
                  </div>

                  <div className="td">
                    <div> { item.tokenSymbol ? item.amountToken + ' ' + item.tokenSymbol : item.amountCoin + ' ' + pageStore.currentChain.Coin.symbol} </div>
                  </div> 

                  <div className="td">
                    {item.subsequent > 0 ? item.subsequent : 'no'}&nbsp;{lang.t('deposits')}
                  </div>

                  <div className="td">
                    <a
                      href={
                        `${pageStore.currentChain.explorerURL}/tx/` + item.txHash
                      }
                      className="colorfulA"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {Utility.toFixString(item.txHash, 10)}
                    </a>
                  </div>
                  <div className="td">{store.showStatus(item.status)}</div>
                  <div className="td" style={{padding: '20px 0px 20px 0px'}}>
                    <button
                      onClick={() => {
                        store.onClickCopyButton(index);
                      }}
                      className="imageButton"
                      style={{
                        backgroundColor: 'rgb(108, 222, 212)',
                        color: 'black',
                        borderRadius: '5px',
                        fontWeight: 'bold',
                        display: 'flex',
                        flexDirection: 'row'
                      }}
                    >
                      <img src="/images/copyicon.png" alt="Copy Button" />
                      <span>{lang.t('note')}&nbsp;</span>
                    </button>
                  </div>
                  <div className="td" style={{padding: '20px 0px 20px 0px'}}>
                    <Popconfirm title={lang.t('note.delte.confirm')} onConfirm={() => {
                      store.onClickRemoveButton(index);
                      }}>
                      <button id={index} className="imageButton">
                        <img src="/images/closeicon.png" alt="Remove Button" />
                      </button>
                    </Popconfirm>
                  </div>
                </div>
              ))}
            </div>
            
          </div>
        </div>
      )}

      <input
        ref={node => (clipboardy = node)}
        style={{
          width: '1px',
          height: '1px',
          border: 'none',
          backgroundColor: 'transparent',
          padding: 0,
          fontSize: '3px'
        }}
        defaultValue={props.note}
      />
    </>
  );
});

export default TransactionRecord;
