import React, { useEffect, useState } from 'react';
import { observer, useLocalStore } from 'mobx-react-lite';
import { useStore } from '../../../store';

import { TransactionRecord } from '../../../components/TransactionRecord';


export const TransactionLog = observer((props) => {
  const { transaction, god } = useStore();
  
  return (
    <div style={{ display: props.isChosen? 'block' : 'none' }}>
      {
        god.isPublicInfoLoaded && god.isPrivateInfoLoaded &&
          <TransactionRecord
              data = { transaction.currentTransactions }
              removeTransactionRecord = {(id) => {
                // console.log('id', id);
                // console.log('transaction.currentTransactions[id]', transaction.currentTransactions[id]);
                transaction.removeTransaction(transaction.currentTransactions[id]);
              }}
          />
      }
    </div>
  );
});
