import { observer, useLocalStore } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useStore } from '../store';
import Button from './Button';

interface ILoadingProps {
  text: string;
} 

export const Loading = observer((props: ILoadingProps) => {
  const { lang } = useStore();

  const store = useLocalStore(
    (props) => ({
      allowToCancel: false,
      nextable: true,
    }),
    props
  );
  
  return (
    <div className="loadingBar">
      <svg style={{ margin: 'auto', background: 'rgb(255, 255, 255, 0)', display: 'block', shapeRendering: 'auto', width: 200, height: 200 }} viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
      <circle cx="50" cy="50" r="0" fill="none" stroke="#AA65E5" stroke-width="2">
        <animate attributeName="r" repeatCount="indefinite" dur="1s" values="0;39" keyTimes="0;1" keySplines="0 0.2 0.8 1" calcMode="spline" begin="0s"></animate>
        <animate attributeName="opacity" repeatCount="indefinite" dur="1s" values="1;0" keyTimes="0;1" keySplines="0.2 0 0.8 1" calcMode="spline" begin="0s"></animate>
      </circle><circle cx="50" cy="50" r="0" fill="none" stroke="#AA65E5" stroke-width="2">
        <animate attributeName="r" repeatCount="indefinite" dur="1s" values="0;39" keyTimes="0;1" keySplines="0 0.2 0.8 1" calcMode="spline" begin="-0.5s"></animate>
        <animate attributeName="opacity" repeatCount="indefinite" dur="1s" values="1;0" keyTimes="0;1" keySplines="0.2 0 0.8 1" calcMode="spline" begin="-0.5s"></animate>
      </circle>
      </svg>

      <div className="loadingLabel"><p>{props.text.split('#')[0]}</p><p>{props.text.split('#')[1]}</p></div>
    </div>
  );
});

export default Loading;
