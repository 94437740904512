import React, { useEffect } from 'react';
import { css, global } from '../../modules/index';
import { useStore } from '../../store';
import { Link } from 'react-router-dom';
import { Icon, Text, Section } from '../../modules/globalStyle';
import { Menu, Dropdown } from 'antd';
import { observer, useLocalStore, useObserver } from 'mobx-react-lite';
import { helper } from '../../utils/helper';
import { ChainNav } from '../ChainNav/index';
import { isMobile } from "react-device-detect";
import { styled } from '../../modules/index';
import { size } from 'lodash';
import { Pointer } from 'highcharts';

export const Headers = observer((props) => {
  const { lang, god } = useStore();
  const store = useLocalStore(() => ({
    isMouseOver: false,
    isRegistered: false,
    showConnecter() {
      god.setShowConnecter(true);
    },
    mouseOver(event) {
      this.isMouseOver = true;
    },
    mouseOut(event) {
      this.isMouseOver = false;
    },
    showWalletInfo() {
      if(store.isRegistered) {
        god.currentNetwork.walletInfo.visible = true;
      }
      else {
        god.register();
      }
    },
    register() {
    }
  }));

  const menus = (
    <Menu>
      {/* <Menu.Item style={{color: 'white'}}>
        <a href="https://messier.gitbook.io/catalogue-de-messier/horizon">{lang.t('Docs')}</a>
      </Menu.Item>
      <Menu.Item style={{color: 'white'}}>
        <a href="https://github.com/MessierM87/Horizon">Github</a>
      </Menu.Item> */}
      
    </Menu>
  );
  const accountView = useObserver(() => {
    if (!god.myAddress) {
      return (
        // <Text color="yellow" weight="bold" family="Montserrat" cursor="pointer" onClick={store.showConnecter}>
        //   {lang.t('login')}
        // </Text>
        <div style={{display: 'flex', flexDirection: 'row', gap: '10px'}}>
          {/* <SettingButton>
            <Icon src="/images/home/setting.png" alt="" css={{ width: 24, height: 24 }}></Icon>
          </SettingButton> */}
          <ConnectButton onClick={store.showConnecter}>
            <Text cursor="pointer" color="white" size="small" weight="normal" family="Montserrat">Join ZkMix</Text>
          </ConnectButton>
        </div>
      );
    }
    else {
      return (
        <div style={{display: 'flex', flexDirection: 'row', gap: '10px'}}>
          {/* <SettingButton>
            <Icon src="/images/home/setting.png" alt="" css={{ width: 24, height: 24 }}></Icon>
          </SettingButton> */}
          <ConnectButton onClick={store.showWalletInfo}>
            <Text cursor="pointer" color="white" size="small" weight="normal" family="Montserrat">
              {store.isRegistered ? helper.string.truncate(god.myAddress, 12, "...") : "Register Wallet"}
            </Text>
          </ConnectButton>
        </div>
        // <Text size="small" color="yellow" weight="bold" family="Montserrat" cursor="pointer" onClick={store.showWalletInfo}>
        //   {store.isRegistered ? helper.string.truncate(god.myAddress, 12, "...") : lang.t('signup')}
        // </Text>
      );
    }
  });

  useEffect(() => {
    store.isRegistered = god.isRegistered;
  }, [god.isRegistered]);

  return (
    <>

      <div className={styles.cycHeader.className}>
        <div className="cycHeader_l">
          <a href="/">
            <img src="/images/home/zklk_logo.svg" className="logo" alt="" />
          </a>
          
          {/* <ul>
            <li>
              <a href="https://messier.gitbook.io/catalogue-de-messier/horizon">{lang.t('Docs')}</a>
            </li>
            <li>
              <a href="https://github.com/MessierM87/Horizon">Github</a>
            </li>
          </ul> */}
        </div>
        <div className="cycHeader_r">
          {/* <Icon src="/images/home/icon_light.png" alt="" className="light"  /> */}
          <ChainNav />
          {accountView}
        </div>
        {/* <div className={styles.menuMobile.className}>
          <Dropdown overlay={menus} placement="bottomRight">
            <Icon src="/images/home/menu_icon.png" css={{ width: '1.4em', height: '1.3rem', padding: 0 }} alt=""></Icon>
          </Dropdown>

        </div> */}
      </div>
      <Section css={{ mb: '30px', dispaly: 'block', '@md': { display: 'none' } }}>
        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
          <ChainNav />
          <div style={{display: 'flex', alignItems: 'center'}}>
            {accountView}
          </div>
        </div>
      </Section>
    </>
  );
});

const styles = {
  cycHeader: css({
    flexBetweenCenter: 'row',
    marginBottom: '2.5rem',
    fontFamily: 'Montserrat',
    '.ant-dropdown-menu': {
      backgroundColor: '$bg5 !important',
    },
    '.cycHeader_l': {
      display: 'flex',
      alignItems: 'center',

      '.logo': {
        height: '3.5rem',
        marginRight: '5rem',
      },
      ul: {
        display: 'none',
        '@md': {
          display: 'flex',
          alignItems: 'center',
          listStyleType: 'none',
          padding: 0,
          margin: 0,
        },
        li: {
          display: 'flex',
          alignItems: 'center',
          marginRight: '2.375rem',
          a: {
            color: '$textPrimary',
            fontSize: '1rem',
            fontWeight: 'bold',
            '&:hover': {
              color: '$primary',
            },
          },
        },
      },
    },
    '.cycHeader_r': {
      display: 'none',
      '@md': {
        display: 'flex',
        alignItems: 'center',
        '.light': {
          width: '1.6rem',
          height: '1.6rem',
          marginRight: '2.5rem',
        },
      },
    },
  }),
  menuMobile: css({
    display: 'block',
    '@md': {
      display: 'none',
    },
    '.ant-dropdown-trigger': {
      px: 0,
    },
  }),
};

const ConnectButton = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '12px 20px',
  gap: '12px',
  width: '173px',
  height: '50px',
  background: 'linear-gradient(90deg, #AA65E5 0%, #A250E5 100%)',
  borderRadius: '8px',
  cursor: 'pointer'
});

const SettingButton = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '8px',
  gap: '12px',
  width: '50px',
  height: '50px',
  background: 'rgba(170, 101, 229, 0.1)',
  border: '1px solid #3C3C3C',
  borderRadius: '8px',
  cursor: 'pointer'
});

export default Headers;
