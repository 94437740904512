import { makeAutoObservable } from 'mobx';
import { TransactionState } from './lib/TransactionState';
import { rootStore } from './index';

const transactionsKey = 'transactions';

export class TransactionStore {
  transactions: TransactionState[] = [];
  constructor() {
    // localStorage.setItem(transactionsKey, "");
    makeAutoObservable(this);
    const transaction = localStorage.getItem(transactionsKey);
    if (transaction) {
      this.transactions = JSON.parse(transaction).map((i) => new TransactionState(i));
    }
  }

  get currentTransactions() {
    // console.log('transactions', this.transactions.length);
    return this.transactions.filter((i) => i.chainId == rootStore.god.currentChain.chainId);
  }

  fetchTxbyNote(note) {
    // console.log('fetchTxbyNote', this.transactions.length);
    return this.transactions.filter((i) => i.note == note);
  }

  addTransaction(transaciton: TransactionState) {
    this.transactions.push(transaciton);
    // console.log(this.transactions);
    // console.log(JSON.stringify(this.transactions));
    localStorage.setItem(transactionsKey, JSON.stringify(this.transactions));
    // console.log('addTransaction', transaciton);
  }

  removeTransaction(transaciton: TransactionState) {
    // console.log('transaciton.txHash', transaciton.txHash);
    this.transactions = this.transactions.filter((i) => i.txHash !== transaciton.txHash);
    localStorage.setItem(transactionsKey, JSON.stringify(this.transactions));
  }

  save() {
    localStorage.setItem(transactionsKey, JSON.stringify(this.transactions));
  }
}
