import React, { useState } from 'react';
import { styled } from '../../modules/index';
import { Icon, Text, FlexBox, TextArea, Button, Section } from '../../modules/globalStyle';
import { DialogWrapper } from '../Dialog/index';
import { useStore } from '../../store';
import { observer, useLocalStore } from 'mobx-react-lite';
import { message } from 'antd';
import copy from 'copy-to-clipboard';
interface IComponentProps {
  visible?: boolean;
  close?: any;
  note?: any;
  onConfirm?: Function;
  onCancel?: Function;
}

const NoteBox = styled('div', {
  backgroundColor: '$bg3',
  p: '1rem'
});

export const CheckNote = observer((props: IComponentProps) => {
  const { lang, god } = useStore();
  const { visible, close } = props;
  const store = useLocalStore(
    (props) => ({
      invalid: false,
      keyConfirm: '',
      copy(data) {
        copy(data);
        message.success(lang.t('check.note.copied'));
      },
      get isConfirm() {
        return store.keyConfirm != '';
      },
      async onConfirm() {
        god.isRegistered = false;
        god.setLoadingText(lang.t('horizon.connect'));
        god.setLoadingView(true);
        const { invalid } = await god.checkPrivateKey(store.keyConfirm);
        store.invalid = !god.isRegistered;
        if(!store.invalid) {
          god.setLoadingText(lang.t('Loading.text9'));
          await Promise.all(god.currentChain.poolList.map( async (pool) => {
            await god.checkCommitmentAndNullifer(pool);
          }));
          god.loginType = "key";
          props.onConfirm();
        }
        god.setLoadingView(false);
      }
    }),
    props
  );

  const content = (
    <Section>
      {
        store.invalid &&
        <Text size="md" color="warning" css={{ mb: '1.2rem' }}>
          {lang.t('backup.red')}
        </Text>
      }
      <div>
        <Text size="md" color="white" css={{ mb: '1.5rem' }}>
          {lang.t('check.ky.textarea')}
        </Text>

        <TextArea value={store.keyConfirm} css={{ mb: '2rem' }} onChange={(e) => (store.keyConfirm = e.target.value)} />

        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
          <Button disabled={!store.isConfirm} style={{width: '48%'}} onClick={(e) => store.onConfirm()} color="primary" responsive="large">
            {lang.t('confirm')}
          </Button>
          <Button style={{width: '48%'}} onClick={(e) => props.onConfirm()} color="primary" responsive="large">
            {lang.t('Cancel')}
          </Button>
        </div>
        
      </div>
    </Section>
  );
  return <DialogWrapper title={lang.t('shielded.key')} size="medium" content={content} visible={visible} close={close} />;
});
